import { gql } from '@apollo/client';

export const GET_WISHLIST_ITEMS_COUNT = gql`
    query getWishlistItemsCount {
        customer {
            wishlists {
                id
                items_count
            }
        }
    }
`;
