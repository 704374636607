import { useCallback } from 'react';
import { setSearchParams } from '@/components/SearchBar/hooks/useSearchBar';
import type { IUseSuggestedProduct, IUseSuggestedProductProps } from '@/components/SearchBar/types';

export const useSuggestedProduct = ({
    onClick,
    productUrl,
    searchValue,
}: IUseSuggestedProductProps): IUseSuggestedProduct => {
    const nextSearchParams = setSearchParams(searchValue, 'autosuggest_top_product', true);
    const destination = `/${productUrl}&${nextSearchParams}`;

    const handleClick = useCallback(() => {
        if (typeof onClick === 'function') {
            onClick();
        }
    }, [onClick]);

    return {
        destination,
        handleClick,
    };
};
