import React, { ReactElement } from 'react';
import { FiTrash2 as TrashIcon } from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import Link from '@/components/Link';
import { useItem } from '@/components/MiniCart/hooks/useItem';
import ProductOptions from '@/components/ProductOptions';
import OptimizedImage from '@/components/OptimizedImage';
import Button from '@/components/Button';
import { CardActionOverlay } from '@/components/Card';
import ProductPrice from '@/components/ProductPrice';
import Quantity from '@/components/Quantity';
import Notification from '@/components/Notification';
import { isDonationProduct } from '@vaimo/donation-products';
import { ImageWidthsEnum } from '@/components/OptimizedImage/types';
import type { IHasLinkToPdpProps, IItemProps } from './types';
import classes from './styles/item.module.scss';

const IMAGE_SIZE = 100;

const Item = ({
    closeMiniCart,
    configurable_options,
    configurableThumbnailSource,
    handleCancelRemoveItem,
    handleConfirmRemoveItem,
    handleRemoveItem,
    id,
    isConfirmingRemove,
    prices,
    product,
    quantity,
    setIsUpdatingQuantity,
}: IItemProps): ReactElement => {
    const tGlobal = useTranslations('global');
    const tProductDetail = useTranslations('productDetail');

    const { errorMessage, flatProduct, handleUpdateItemQuantity, isDeleting, isUpdating, removeItem } = useItem({
        configurable_options,
        configurableThumbnailSource,
        handleRemoveItem,
        id,
        prices,
        product,
        quantity,
        setIsUpdatingQuantity,
    });
    const { destination, image, isOutOfStock, name, productPrices } = flatProduct;
    const isDonationProductType = isDonationProduct(product);

    const maybeConfirmingDeleteOverlay = (isConfirmingRemove || isDeleting) && (
        <CardActionOverlay isLoading={isDeleting}>
            <Button disabled={isDeleting} type="button" variant={'secondary'} onClick={handleCancelRemoveItem} isSmall>
                {tGlobal('cancelButton')}
            </Button>
            <Button disabled={isDeleting} type="button" onClick={removeItem} isSmall>
                <TrashIcon />
                {tGlobal('removeButton')}
            </Button>
        </CardActionOverlay>
    );

    const HasLinkToPdp = ({ children, className }: IHasLinkToPdpProps) =>
        isDonationProductType ? (
            <div>{children}</div>
        ) : (
            <Link className={className} href={destination} onClick={closeMiniCart}>
                {children}
            </Link>
        );

    return (
        <div className={classes.root}>
            {
                <HasLinkToPdp className={classes.itemImage}>
                    <OptimizedImage
                        alt={product.name}
                        className={classes.itemImage}
                        src={image}
                        height={IMAGE_SIZE}
                        width={IMAGE_SIZE}
                        unoptimized
                        widths={
                            new Map([
                                [ImageWidthsEnum.THUMBNAIL, ImageWidthsEnum.THUMBNAIL],
                                [100, 100],
                                [ImageWidthsEnum.SMALL, ImageWidthsEnum.SMALL],
                            ])
                        }
                    />
                </HasLinkToPdp>
            }
            <div className={classes.itemContent}>
                <h4 className={classes.itemName}>
                    <HasLinkToPdp className={classes.itemLink}>{name}</HasLinkToPdp>
                </h4>
                <ProductPrice {...productPrices} className={classes.itemPrice} />
                <ProductOptions options={configurable_options} />
                <div className={classes.quantityLineWrapper}>
                    {!isDonationProductType && (
                        <Quantity itemId={id} initialValue={quantity} onChange={handleUpdateItemQuantity} />
                    )}
                    <Button
                        className={classes.removeButton}
                        onClick={handleConfirmRemoveItem}
                        isSmall
                        variant={'trigger'}
                        title={tGlobal('removeButton')}
                    >
                        <TrashIcon size={14} />
                    </Button>
                </div>
                {isOutOfStock ? <span className={classes.stockStatus}>{tProductDetail('outOfStock')}</span> : ''}
            </div>

            {maybeConfirmingDeleteOverlay}
            {isUpdating && <CardActionOverlay isLoading={isUpdating} />}
            {errorMessage && <Notification variant="error">{errorMessage}</Notification>}
        </div>
    );
};

Item.displayName = 'Item';

export default Item;
