'use client';

import React, { lazy, Suspense } from 'react';
import { FiShoppingCart as ShoppingCartIcon } from 'react-icons/fi';
import { useLocale, useTranslations } from 'next-intl';
import { useAppContext } from '@/lib/context';

import { useCartTrigger } from '@/components/Header/modules/CartTrigger/hooks/useCartTrigger';

import Price from '@/components/Price';
import Button from '@/components/Button';

import type { Money } from '@/types/product';
import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './cartTrigger.module.scss';

const Drawer = lazy(() => import('@/components/Drawer'));
const MiniCart = lazy(() => import('@/components/MiniCart'));
const AddToCartSuccessModal = lazy(() => import('@/components/AddToCartSuccessModal'));

interface ICartTriggerProps {
    classes?: {
        [className: string]: string;
    };
}

const getFormattedPrice = (subtotal: Money, locale: string): string => {
    const priceFormatter = Intl.NumberFormat(locale, {
        currency: subtotal?.currency,
        style: 'currency',
    });

    const parts = priceFormatter.formatToParts(subtotal?.value);

    return parts.map((part) => part.value).join('');
};

const CartTrigger = ({ classes: propsClasses }: ICartTriggerProps) => {
    const {
        handleTriggerClick,
        isMiniCartOpen,
        itemCount,
        miniCartRef,
        miniCartTriggerRef,
        setIsMiniCartOpen,
        subtotal,
    } = useCartTrigger();
    const [{ isHeaderNewDesign: isNewDesign, isSearchOpen }] = useAppContext();

    const classes = mergeClasses(defaultClasses, propsClasses);
    const t = useTranslations('cartTrigger');

    const locale = useLocale();
    const cartSubtotal = subtotal ? getFormattedPrice(subtotal, locale) : '';

    const cartTotal = subtotal ? <Price currencyCode={subtotal.currency} value={subtotal.value} /> : null;
    const buttonAriaLabel = t('ariaLabel', { cartTotal: `${cartSubtotal}`, count: itemCount });
    const itemCountDisplay = itemCount > 99 ? '99+' : itemCount;

    const maybeItemCounter = itemCount ? (
        <span className={classes.counter}>
            <span className={classes.counterValue}>{itemCountDisplay}</span>
        </span>
    ) : null;

    const rootClassName = isSearchOpen ? classes.visuallyHidden : classes.triggerWrapper;

    return (
        <div className={rootClassName}>
            <div className={classes.triggerContainer} ref={miniCartTriggerRef}>
                <Button
                    variant={'link'}
                    aria-label={buttonAriaLabel}
                    className={isNewDesign ? classes.triggerNewDesign : classes.trigger}
                    onClick={handleTriggerClick}
                >
                    <span className={classes.iconWrapper}>
                        <ShoppingCartIcon className={classes.icon} />
                        {maybeItemCounter}
                    </span>
                    <span className={classes.subtotal}>
                        <span className={classes.label}>{t('info')}</span>
                        {cartTotal && <span className={classes.subtotalValue}>{cartTotal}</span>}
                    </span>
                </Button>
            </div>
            <Suspense fallback={null}>
                <Drawer isOpen={isMiniCartOpen} setIsOpen={setIsMiniCartOpen} ref={miniCartRef}>
                    <MiniCart setIsOpen={setIsMiniCartOpen} itemCount={itemCount} />
                </Drawer>
            </Suspense>
            <Suspense fallback={null}>
                <AddToCartSuccessModal itemCount={itemCount} shouldShow={!isMiniCartOpen} />
            </Suspense>
        </div>
    );
};

CartTrigger.displayName = 'CartTrigger';

export default CartTrigger;
