'use client';

import React, { ReactElement } from 'react';
import { Typography, Icon } from '@vaimo-int/aem-pwa-common-components';
import { useTranslations } from 'next-intl';
import { useAppContext } from '@/lib/context';
import { STORE_SWITCHER_LOCATION } from '@/constants/storeSwitcher';
import useIsMobile from '@/hooks/useIsMobile';
import getIsGlobalEnabled from '@/utils/getIsGlobalEnabled';
import type { IWelcomeMessage } from '@/layout/main/Footer/types';
import classes from '@/layout/main/Footer/footer.module.scss';

const WelcomeMessage = ({ country, text }: IWelcomeMessage): ReactElement | null => {
    const t = useTranslations('footer');
    const isMobile = useIsMobile();

    const [
        { storeConfig },
        {
            actions: { setIsStoreSwitcherOpen },
        },
    ] = useAppContext();

    const handleOpenStoreSwitcher = () => {
        setIsStoreSwitcherOpen({ isOpen: true, location: STORE_SWITCHER_LOCATION.BOTTOM_RIGHT });
    };

    const handleOpenOnlyOnMobile = () => {
        if (!isMobile) return;
        handleOpenStoreSwitcher();
    };

    const isGlobalEnabled = getIsGlobalEnabled(storeConfig?.storeCode, storeConfig?.globalE || {});

    if (!text) return null;

    return (
        <div
            className={isGlobalEnabled ? classes.footerCountryHide : classes.footerCountry}
            onClick={handleOpenOnlyOnMobile}
            data-mt-type="footer-country-switcher"
        >
            <Typography.Body className={classes.footerCountryText}>
                {country && (
                    <span className={classes.footerFlag} onClick={handleOpenStoreSwitcher}>
                        <Icon name={country} type={'flag'} />
                    </span>
                )}
                {text}
            </Typography.Body>
            <button onClick={handleOpenStoreSwitcher} className={classes.welcomeMessageLink}>
                {t('storeSwitcherLink')}
            </button>
        </div>
    );
};

export default WelcomeMessage;
