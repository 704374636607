import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { useTranslations } from 'next-intl';
import { Form, Formik, FormikProps } from 'formik';
import Notification from '@/components/Notification';
import { NOTIFICATION_VARIANTS } from '@/components/Notification/types';
import TextInput from '@/components/TextInput';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Select from '@/components/Select';
import { useReCaptcha } from '@vaimo/google-recaptcha';
import { useFormSubscribe } from '@/components/Newsletter/FormSubscribe/useFormSubscribe';
import PolicyLink from '@/components/Newsletter/PolicyLink';
import { useRequestContext } from '@/lib/context';

import { validationSchema } from './validationSchema';
import type { TNewsletterSetState } from '@/components/Newsletter/types';
import classes from '@/components/Newsletter/newsletter.module.scss';

const FormSubscribe = ({ setStep }: { setStep: TNewsletterSetState }): ReactElement => {
    const tNewsletter = useTranslations('newsletter');
    const tFormError = useTranslations('formError');
    const tForm = useTranslations('form');
    const request = useRequestContext();
    const formRef = useRef<FormikProps<{ countryId: string }>>(null);

    const { countries, error, handleSubmit } = useFormSubscribe({ setStep });
    const { handleSubmitWithReCaptcha, isReCaptchaLoading, reCaptchaComponent }: any = useReCaptcha({
        onSubmit: handleSubmit,
        reCaptchaConfigField: 'newsletter',
    });

    const [initCountryId, mappedCountries] = useMemo(() => {
        // We have 200+ countries
        let initCountryId: string = '';
        const mappedCountries: Array<{ label: string; value: string }> = countries.map((country) => {
            if (country.code && country.code.toLowerCase() === request.storeCode.split('_')[1]) {
                initCountryId = country.id;
            }

            return { label: country.value, value: country.id };
        });

        return [initCountryId, mappedCountries];
    }, [countries]);

    useEffect(() => {
        if (formRef.current && initCountryId) {
            formRef.current.setFieldValue('countryId', initCountryId, false);
        }
    }, [initCountryId]);

    return (
        <Formik
            innerRef={formRef}
            initialValues={{ countryId: '', email: '', newsletter: false }}
            onSubmit={handleSubmitWithReCaptcha}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
        >
            <Form className={classes.form}>
                <h2 className={classes.title} data-mt-type="newsletter-title">
                    {tNewsletter('title')}
                </h2>
                {error && (
                    <Notification variant={NOTIFICATION_VARIANTS.error}>{tFormError('errorMessage')}</Notification>
                )}
                <div className={classes.message} data-mt-type="newsletter-message">
                    {tNewsletter('signUp')}
                </div>
                <div className={classes.emailWrapper} data-mt-type="newsletter-email">
                    <TextInput
                        aria-label={tNewsletter('emailAddress')}
                        autoComplete="email"
                        field="email"
                        isRequired
                        type="email"
                        label={tForm('email')}
                    />
                    {countries && (
                        <Select label={tNewsletter('country')} field="countryId" items={mappedCountries} isRequired />
                    )}
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={isReCaptchaLoading}
                        aria-label={tNewsletter('signUpButton')}
                        title={tNewsletter('submitSubscription')}
                    >
                        {tNewsletter('signUpButton')}
                    </Button>
                </div>
                <Checkbox
                    id="newsletter"
                    field="newsletter"
                    label={
                        <span className={classes.agreement}>
                            {tNewsletter.rich('agreement', { policyLink: PolicyLink })}&#42;
                        </span>
                    }
                />
                {!!reCaptchaComponent && <div className={classes.grecaptchaBadge}>{reCaptchaComponent}</div>}
            </Form>
        </Formik>
    );
};

export default FormSubscribe;
