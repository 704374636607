import { gql } from '@apollo/client';

export const signOutMutation = gql`
    mutation SignOutFromModal {
        revokeCustomerToken {
            result
        }
    }
`;

export const createCartMutation = gql`
    mutation createCart {
        cartId: createEmptyCart
    }
`;
