import React, { ReactElement } from 'react';
import Notification from '@/components/Notification';
import { NOTIFICATION_VARIANTS } from '@/components/Notification/types';
import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './formError.module.scss';

interface IFormError {
    classes?: {
        [className: string]: string;
    };
    errors?: string | null;
}

const FormError = ({ classes: propClasses, errors }: IFormError): ReactElement | null => {
    const classes = mergeClasses(defaultClasses, propClasses);

    return errors ? (
        <Notification className={classes.root} variant={NOTIFICATION_VARIANTS.error}>
            {errors}
        </Notification>
    ) : null;
};

export default FormError;
