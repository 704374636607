import { useMediaQuery } from 'react-responsive';
// As we are using prerender.io to prerender our application, it's important to also remember,
// that it has no possibility to utilise pointer type (robots don't have a mouse, nor a human finger). We have to manage that separately.

// Important notice: Please don't use to conditionally render any components, if it breaks the rule of not having unified mobile and desktop application structure
// ex: isDesktop ? <Component/> : null;
// reference: https://developers.google.com/search/mobile-sites/mobile-first-indexing#content-is-same
interface IDesktopProps {
    minWidth?: string;
    query?: string;
}

const useIsDesktop = (props: IDesktopProps = {}): boolean => {
    // Added 1px converted to rem units, because useIsMobile's border screen size equals 48rem
    const { minWidth = '48.063rem', query = '(pointer: coarse)' } = props;
    const isWidthCorrect = useMediaQuery({ minWidth });
    // touch device only
    const isPointerCoarse = useMediaQuery({ query });

    // Inverted logic here also allows for pointer agnostic crawlers
    return isWidthCorrect && !isPointerCoarse;
};

export default useIsDesktop;
