import { useCallback, useEffect, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { clearCartDataFromCache } from '@/lib/store/utils/clearCartDataFromCache';
import { useCartContext, useUserContext } from '@/lib/context';
import useTakeMeHome from '@/hooks/useTakeMeHome';
import { PAGE_TYPES, usePageType } from '@vaimo/google-tag-manager';
import { createCartMutation, signOutMutation } from '../api/authModal.gql';
import type { IUseAuthModal, IUseAuthModalProps } from '../types';

const UNAUTHED_ONLY = ['CREATE_ACCOUNT', 'FORGOT_PASSWORD', 'SIGN_IN'];

export const useAuthModal = ({
    closeDrawer,
    showCreateAccount,
    showForgotPassword,
    showMainMenu,
    showMyAccount,
    showSignIn,
    view,
}: IUseAuthModalProps): IUseAuthModal => {
    const apolloClient = useApolloClient();

    const [isSigningOut, setIsSigningOut] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [gtmSignOut, setGtmSignOut] = useState(null);
    const [{ currentUser, isSignedIn }, { signOut }] = useUserContext();
    const [, { createCart }] = useCartContext();

    const [fetchCartId] = useMutation(createCartMutation);
    const [revokeToken] = useMutation(signOutMutation);
    const takeMeHome = useTakeMeHome();

    // If the user is authed, the only valid view is "MY_ACCOUNT".
    // view an also be `MENU` but in that case we don't want to act.
    useEffect(() => {
        if (currentUser?.email && !!view && UNAUTHED_ONLY.includes(view)) {
            showMyAccount?.();
        }
    }, [currentUser, showMyAccount, view]);

    // If the user token was invalidated by way of expiration, we need to reset
    // the view back to the main menu.
    useEffect(() => {
        if (!isSignedIn && view === 'MY_ACCOUNT' && !isSigningOut) {
            showMainMenu?.();
        }
    }, [isSignedIn, isSigningOut, showMainMenu, view]);

    // On successful login using mobile menu, we need to redirect to my account, and close drawer
    useEffect(() => {
        if (isSignedIn && view === 'SIGN_IN' && !isSigningOut) {
            closeDrawer?.();
            showMyAccount?.();
        }
    }, [closeDrawer, isSignedIn, isSigningOut, showMainMenu, showMyAccount, view]);

    const handleClose = useCallback(() => {
        showMainMenu?.();
        closeDrawer?.();
    }, [closeDrawer, showMainMenu]);

    const handleCancel = useCallback(() => {
        showSignIn?.();
    }, [showSignIn]);

    const handleCreateAccount = useCallback(() => {
        showMyAccount?.();
    }, [showMyAccount]);

    const clearAndCreateCart = useCallback(async () => {
        await clearCartDataFromCache(apolloClient);
        await createCart({
            fetchCartId,
        });
    }, []);

    const handleSignOut = useCallback(async () => {
        setIsSigningOut(true);
        setGtmSignOut(PAGE_TYPES.SIGN_OUT);

        // Delete cart/user data from the redux store.
        await signOut({ revokeToken });

        await takeMeHome();

        clearAndCreateCart();
    }, [revokeToken, signOut, takeMeHome]);

    usePageType({ pageType: gtmSignOut });

    return {
        handleCancel,
        handleClose,
        handleCreateAccount,
        handleSignOut,
        setUsername,
        showCreateAccount,
        showForgotPassword,
        showMyAccount,
        showSignIn,
        username,
    };
};
