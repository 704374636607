import { Dispatch, SetStateAction } from 'react';

export enum StepEnum {
    SUBSCRIBE,
    UPDATE,
    COMPLETE,
}

export interface INewsLetterState {
    currentStep: StepEnum;
    data?: any;
    email?: string;
}

export type TNewsletterSetState = Dispatch<SetStateAction<INewsLetterState>>;

interface DataItem {
    id: string;
    value: string;
}

interface CountryDataItem extends DataItem {
    code: string | null;
}

export interface INewsLetterFormData {
    ageGroups: {
        items: Array<DataItem>;
    };
    countries: Array<CountryDataItem>;
    countryId?: string;
    genders: Array<DataItem>;
    sports: Array<DataItem>;
}

export interface IUseSubscribeNewsletter {
    setState: TNewsletterSetState;
    state: INewsLetterState;
}

export interface IFormContext {
    headers: {
        'X-ReCaptcha': string;
    };
}
