'use client';

import { MutableRefObject, useCallback, useRef, useState } from 'react';

import { useEventListener } from './useEventListener';

interface IUseDropdown {
    elementRef: MutableRefObject<HTMLDivElement | null>;
    expanded: boolean;
    setEvent: (eventName: string) => void;
    setExpanded: (value: ((prevState: boolean) => boolean) | boolean) => void;
    triggerRef: MutableRefObject<HTMLDivElement | null>;
}

export const useDropdown = (): IUseDropdown => {
    const elementRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [event, setEvent] = useState<string>('mousedown');

    // collapse on mousedown outside of the element and trigger.
    const maybeCollapse = useCallback(({ target }: { target: Node }) => {
        const isOutsideElement = !elementRef.current?.contains(target);
        const isOutsideTrigger = !triggerRef.current?.contains(target);

        if (isOutsideElement && isOutsideTrigger) {
            setExpanded(false);
        }
    }, []);

    useEventListener(globalThis.document, event, maybeCollapse);

    return {
        elementRef,
        expanded,
        setEvent,
        setExpanded,
        triggerRef,
    };
};
