'use client';

import React, { ReactElement, Suspense, useCallback, useMemo, useState } from 'react';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import { usePathname } from 'next/navigation';
import Link from '@/components/Link';
import { useAppContext } from '@/lib/context';
import type { IMegaMenuItemProps } from './types';
import clsx from 'clsx';
import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './megaMenuItem.module.scss';

const Submenu = React.lazy(() => import('@/components/MegaMenu/submenu'));

const MegaMenuItem = ({ category, classes: propsClasses }: IMegaMenuItemProps): ReactElement => {
    const [{ isHeaderNewDesign: isNewDesign }] = useAppContext();
    const {
        children: categoryChildren,
        menu_item_link: menuItemLink,
        name,
        url_path: urlPath,
        url_suffix: urlSuffix,
    } = category || {};
    const classes = mergeClasses(defaultClasses, propsClasses);
    const resourceUrl = useResourceUrl();
    const pathname = usePathname();

    const categoryUrl = resourceUrl(`${menuItemLink?.replace('{{base_url}}', '/') || `/${urlPath}`}${urlSuffix || ''}`);
    const [isItemHoveredOver, setIsItemHoveredOver] = useState(false);

    const handleItemUnhover = useCallback(() => {
        sessionStorage.setItem('intentViewItemList', 'positive');
        setIsItemHoveredOver(false);
    }, []);

    const children =
        categoryChildren.length > 0 ? (
            <Suspense fallback={null}>
                <Submenu
                    items={categoryChildren}
                    setIsItemHoveredOver={setIsItemHoveredOver}
                    shouldRenderSubmenu={isItemHoveredOver}
                />
            </Suspense>
        ) : null;

    const rootClassName = clsx(
        classes.megaMenuItem,
        isItemHoveredOver ? classes.isItemHoveredOver : classes.megaMenuItem,
        isNewDesign && classes.megaMenuItemNewDesign,
    );
    const isMainCategoryActive = pathname.includes(urlPath);

    const categoryLink = useMemo(() => {
        const children = <span className={categoryChildren ? classes.megaMenuLinkText : ''}>{name}</span>;
        const commonProps = {
            className: clsx(
                classes.megaMenuLink,
                isMainCategoryActive && classes.megaMenuLinkActive,
                isNewDesign && classes.megaMenuLinkNewDesign,
            ),
            href: categoryUrl,
            onClick: handleItemUnhover,
        };

        return <Link {...commonProps}>{children}</Link>;
    }, [categoryUrl, name, isMainCategoryActive]);

    return (
        <div
            className={rootClassName}
            onMouseOver={() => setIsItemHoveredOver(true)}
            onMouseOut={() => setIsItemHoveredOver(false)}
            data-mt-type="mega-menu-item"
        >
            {categoryLink}
            {children}
        </div>
    );
};

MegaMenuItem.displayName = 'MegaMenuItem';

export default MegaMenuItem;
