'use client';

import React, { createContext } from 'react';
import { useHeader } from '../hooks/useHeader';
import type { IHeaderContextContext, IHeaderProvider } from '../types';

export const HeaderContext = createContext<IHeaderContextContext>({} as IHeaderContextContext);

export const HeaderProvider = ({ children, isCatalogueModeEnabled = false, isNewDesign }: IHeaderProvider) => {
    const { isHeaderVisible } = useHeader();

    return (
        <HeaderContext.Provider value={{ isCatalogueModeEnabled, isHeaderVisible, isNewDesign }}>
            {children}
        </HeaderContext.Provider>
    );
};
