'use client';

import React, { ReactElement, ReactNode, useCallback } from 'react';
import { useTranslations } from 'next-intl';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import Link from '@/components/Link';
import { useTopLinks } from './hooks/useTopLinks';

import type { ITopMenuLink } from '@/components/TopLinks/types';
import classes from './topLinks.module.scss';

export interface TopLinksWrapperProps {
    children?: ReactNode;
}

interface TopLinksProps extends TopLinksWrapperProps {
    topMenuLinks: ITopMenuLink[];
}

const DATA_MT_TYPE = 'top-links-item';

const TopLinks = ({ children, topMenuLinks }: TopLinksProps): ReactElement => {
    const tTopLinks = useTranslations('topLinks');
    const resourceUrl = useResourceUrl();
    const { handleSignOut, isShowAccountMenu, wishlistLinkTitle } = useTopLinks();

    const generateMenuItemLink = useCallback(
        ({ title, url }: ITopMenuLink) => {
            if (!title || !url) {
                return null;
            }

            return (
                <li key={url} className={classes.listItem} data-mt-type={DATA_MT_TYPE}>
                    <Link className={classes.itemLink} href={resourceUrl(url.startsWith('https://') ? url : `/${url}`)}>
                        {title}
                    </Link>
                </li>
            );
        },
        [resourceUrl],
    );

    return (
        <ul className={classes.linksList} data-mt-type="top-links">
            {topMenuLinks?.length > 0
                ? topMenuLinks.map((topMenuLinkItem) => generateMenuItemLink(topMenuLinkItem))
                : null}
            {isShowAccountMenu ? (
                <>
                    <li className={classes.listItem} data-mt-type={DATA_MT_TYPE}>
                        <Link className={classes.itemLink} href={resourceUrl('/wishlist')} title={wishlistLinkTitle}>
                            {wishlistLinkTitle}
                        </Link>
                    </li>
                    <li className={classes.listItem} data-mt-type={DATA_MT_TYPE}>
                        <Link
                            className={classes.itemLink}
                            href={resourceUrl('/customer/account')}
                            title={tTopLinks('customerAccount')}
                        >
                            {tTopLinks('customerAccount')}
                        </Link>
                    </li>
                    <li className={classes.listItem} data-mt-type={DATA_MT_TYPE}>
                        <button className={classes.itemLinkLogOut} onClick={handleSignOut} title={tTopLinks('signOut')}>
                            {tTopLinks('signOut')}
                        </button>
                    </li>
                </>
            ) : (
                <>
                    <li className={classes.listItem} data-mt-type={DATA_MT_TYPE}>
                        <Link
                            className={classes.itemLink}
                            href={resourceUrl(`/customer/account/login`)}
                            title={tTopLinks('signIn')}
                        >
                            {tTopLinks('signIn')}
                        </Link>
                    </li>
                    <li className={`${classes.listItem} ${classes.divider}`} data-mt-type={DATA_MT_TYPE}>
                        <Link
                            className={classes.itemLink}
                            href={resourceUrl(`/customer/account/create`)}
                            title={tTopLinks('createAccount')}
                        >
                            {tTopLinks('createAccount')}
                        </Link>
                    </li>
                </>
            )}
            {children && (
                <li className={classes.listItem} data-mt-type={DATA_MT_TYPE}>
                    {children}
                </li>
            )}
        </ul>
    );
};

TopLinks.displayName = 'TopLinks';

export default TopLinks;
