import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import useIsDesktop from '@/hooks/useIsDesktop';
import useIsMobile from '@/hooks/useIsMobile';

import SuggestedKeywords from '../SuggestedKeywords';
import SuggestedCategories from '../SuggestedCategories';
import SuggestedProducts from '../SuggestedProducts';

import type { ISuggestionsProps } from '@/components/SearchBar/types';
import classes from './suggestions.module.scss';

const Suggestions = ({
    fredHopperCategories,
    fredHopperProducts,
    fredHopperSuggestions,
    isSuggestionsFetchRunning,
    searchQueryValue,
    setIsSearchOpen,
}: ISuggestionsProps): ReactElement => {
    const isDesktop = useIsDesktop();
    const isMobile = useIsMobile({ maxWidth: '47.938rem' });

    const hasSuggestions = !!fredHopperSuggestions?.length;
    const hasCategories = !!fredHopperCategories?.length;
    const hasProducts = !!fredHopperProducts?.length;

    const sectionHeadingSkeleton = <Skeleton height={18} width={150} />;
    const suggestedKeywordAndCategorySkeleton = <Skeleton count={1} height={14} width={160} />;
    const suggestedProductSkeleton = <Skeleton height={250} />;

    if (isSuggestionsFetchRunning) {
        return (
            <div className={classes.suggestionsWrapper}>
                <div className={classes.skeletonSuggestions}>
                    {sectionHeadingSkeleton}
                    <div className={classes.skeletonSuggestionsSection}>
                        {suggestedKeywordAndCategorySkeleton}
                        {suggestedKeywordAndCategorySkeleton}
                        {suggestedKeywordAndCategorySkeleton}
                        {suggestedKeywordAndCategorySkeleton}
                        {isDesktop && (
                            <>
                                {suggestedKeywordAndCategorySkeleton}
                                {suggestedKeywordAndCategorySkeleton}
                                {suggestedKeywordAndCategorySkeleton}
                            </>
                        )}
                    </div>
                </div>
                <div className={classes.skeletonSuggestions}>
                    {sectionHeadingSkeleton}
                    <div className={classes.skeletonSuggestionsSection}>
                        {suggestedKeywordAndCategorySkeleton}
                        {suggestedKeywordAndCategorySkeleton}
                        {isDesktop && (
                            <>
                                {suggestedKeywordAndCategorySkeleton}
                                {suggestedKeywordAndCategorySkeleton}
                            </>
                        )}
                    </div>
                </div>
                <div className={classes.skeletonSuggestions}>
                    {sectionHeadingSkeleton}
                    {isMobile ? (
                        <>
                            <div className={classes.skeletonProducts}>
                                {suggestedProductSkeleton}
                                {suggestedProductSkeleton}
                            </div>
                            <div className={classes.skeletonProducts}>
                                {suggestedProductSkeleton}
                                {suggestedProductSkeleton}
                            </div>
                        </>
                    ) : (
                        <div className={classes.skeletonProducts}>
                            {suggestedProductSkeleton}
                            {suggestedProductSkeleton}
                            {suggestedProductSkeleton}
                            {suggestedProductSkeleton}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={classes.suggestionsWrapper}>
            {hasSuggestions && (
                <section className={classes.sectionWrapper}>
                    <SuggestedKeywords
                        searchQueryValue={searchQueryValue}
                        setIsSearchOpen={setIsSearchOpen}
                        fredHopperSuggestions={fredHopperSuggestions}
                    />
                </section>
            )}
            {hasCategories && (
                <section className={classes.sectionWrapper}>
                    <SuggestedCategories
                        searchQueryValue={searchQueryValue}
                        setIsSearchOpen={setIsSearchOpen}
                        fredHopperCategories={fredHopperCategories}
                    />
                </section>
            )}
            {hasProducts && (
                <section className={classes.sectionWrapper}>
                    <SuggestedProducts
                        searchQueryValue={searchQueryValue}
                        setIsSearchOpen={setIsSearchOpen}
                        fredHopperProducts={fredHopperProducts}
                    />
                </section>
            )}
        </div>
    );
};

Suggestions.displayName = 'Suggestions';

export default Suggestions;
