import React, { MutableRefObject, ReactElement, ReactNode } from 'react';
import { Field, useField } from 'formik';
import Message from '@/components/FormMessage';

import clsx from 'clsx';
import classes from './searchInput.module.scss';

interface ISearchInputProps {
    'aria-controls'?: string;
    'aria-expanded'?: boolean;
    'aria-label'?: string;
    'aria-required'?: boolean;
    autoCapitalize?: string;
    autoComplete?: string;
    autoCorrect?: string;
    children?: ReactNode;
    className: string;
    field: string;
    forwardedRef: MutableRefObject<HTMLInputElement | undefined>;
    label: string;
    maxLength?: string;
    message?: string;
    onFocus?: () => void;
    onKeyUp?: (e: Event) => void;
    required?: boolean;
    role?: string;
    spellCheck?: string;
    title?: string;
}

const SearchInput = ({
    children,
    className,
    field,
    forwardedRef,
    label,
    message,
    required,
    ...rest
}: ISearchInputProps): ReactElement => {
    const [, meta] = useField(field);

    const error = meta.error;
    const success = !error && meta.value;
    const labelData = `${label || field} ${required ? '*' : ''}`;
    const rootClassName = clsx(className, classes.root, error && classes.error, success && classes.success);

    return (
        <>
            <Field
                {...rest}
                field={field}
                innerRef={forwardedRef}
                className={rootClassName}
                name={field}
                placeholder={labelData}
                type={'text'}
            />
            <label className={classes.label} htmlFor={field}>
                {labelData}
            </label>
            {error || message ? <Message error={error}>{message}</Message> : null}
            {children}
        </>
    );
};

SearchInput.displayName = 'SearchInput';

export default SearchInput;
