import React, { ReactElement, ReactNode } from 'react';

import defaultClasses from './card.module.scss';

interface ICardProps {
    children: ReactNode;
    hasParentCard: boolean;
}

const Card = (props: ICardProps): ReactElement => {
    const { children, hasParentCard } = props;

    return (
        <div className={hasParentCard ? defaultClasses.hasParentCard : defaultClasses.root} data-mt-type="card">
            {children}
        </div>
    );
};

Card.displayName = 'Card';

export default Card;
