'use client';

import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';
import { CookieSettingsButton } from '@vaimo-int/one-trust';

import classes from '@/layout/main/Footer/footer.module.scss';

const CookieSettings = (): ReactElement => {
    const t = useTranslations('footer');

    return (
        <CookieSettingsButton
            onClick={undefined}
            label={t('cookieSettings')}
            Tag={'span'}
            className={classes.footerNavigationLink}
        />
    );
};

export default CookieSettings;
