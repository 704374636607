import React, { ReactElement } from 'react';
import Link from '@/components/Link';
import { useSuggestedProduct } from './hooks/useSuggestedProduct';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import Price from '@/components/Price';
import OptimizedImage from '@/components/OptimizedImage';

import type { ISuggestedProductProps } from '@/components/SearchBar/types';
import classes from './suggestedProduct.module.scss';

const IMAGE_WIDTH = 140;

const SuggestedProduct = ({
    fredHopperProduct,
    searchQueryValue,
    setIsSearchOpen,
}: ISuggestedProductProps): ReactElement => {
    const { destination, handleClick } = useSuggestedProduct({
        onClick: () => setIsSearchOpen(false),
        productUrl: fredHopperProduct.url_variant,
        searchValue: searchQueryValue,
    });
    const resourceUrl = useResourceUrl();

    return (
        <Link
            className={classes.suggestionLink}
            href={resourceUrl(destination)}
            onClick={handleClick}
            data-suggestion-item={true}
        >
            <OptimizedImage
                alt={fredHopperProduct.name}
                src={fredHopperProduct._thumburl}
                unoptimized
                height={IMAGE_WIDTH}
                width={IMAGE_WIDTH}
            />
            <span className={classes.productName}>{fredHopperProduct.name}</span>
            <span className={classes.productPrice}>
                <Price currencyCode={fredHopperProduct.currency} value={+fredHopperProduct.price} />
            </span>
        </Link>
    );
};

SuggestedProduct.displayName = 'SuggestedProduct';

export default SuggestedProduct;
