import React from 'react';
import { useTranslations } from 'next-intl';
import classes from '@/components/Newsletter/newsletter.module.scss';

const PolicyLink = () => {
    const t = useTranslations('newsletter');

    const text = t('policyLink');

    return (
        <a
            href={'https://www.hellyhansen.com/privacy-policy'}
            className={classes.agreementLink}
            role="link"
            aria-label={text}
        >
            {text}
        </a>
    );
};

export default PolicyLink;
