import React, { ReactElement } from 'react';
import Link from '@/components/Link';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import { useSuggestedCategory } from './hooks/useSuggestedCategory';
import { lazy } from '@loadable/component';

import type { ISuggestedCategoryProps } from '@/components/SearchBar/types';
import classes from '../Suggestions/suggestionHighlight.module.scss';

const Highlighter = lazy(() => import('react-highlight-words'));

const SuggestedCategory = ({
    fredHopperCategory,
    searchQueryValue,
    setIsSearchOpen,
}: ISuggestedCategoryProps): ReactElement => {
    const { destination, handleClick } = useSuggestedCategory({
        categoryUrl: fredHopperCategory.category_url,
        onClick: () => setIsSearchOpen(false),
        searchValue: searchQueryValue,
    });
    const resourceUrl = useResourceUrl();

    return (
        <Link
            className={classes.suggestionLink}
            href={resourceUrl(destination)}
            title={fredHopperCategory.category_url}
            onClick={handleClick}
            data-suggestion-item={true}
        >
            <Highlighter
                highlightClassName={classes.highlightClassName}
                unhighlightClassName={classes.unHighlightClassName}
                searchWords={[searchQueryValue]}
                autoEscape={true}
                textToHighlight={fredHopperCategory.mlValue}
            />
            <span className={classes.itemsCount}>{fredHopperCategory.nrResults}</span>
        </Link>
    );
};

SuggestedCategory.displayName = 'SuggestedCategory';

export default SuggestedCategory;
