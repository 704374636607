import { useCallback } from 'react';
import { useAppContext } from '@/lib/context';

interface IUseNavigationTrigger {
    handleOpenNavigation: () => void;
    isSearchOpen: boolean;
}

export const useNavigationTrigger = (): IUseNavigationTrigger => {
    const [{ isSearchOpen }, { toggleDrawer }] = useAppContext();

    const handleOpenNavigation = useCallback(() => {
        toggleDrawer('nav');
    }, [toggleDrawer]);

    return {
        handleOpenNavigation,
        isSearchOpen,
    };
};
