import { useTranslations } from 'next-intl';
import { useUserContext } from '@/lib/context';
import { useAuthModal } from '@/components/AuthModal/hooks/useAuthModal';
import { useWishlistItemsCount } from '@/components/WishlistItemsCount/hooks/useWishlistItemsCount';

import type { IUseTopLinks } from '../types';

export const useTopLinks = (): IUseTopLinks => {
    const t = useTranslations('wishlist');
    const [{ isSignedIn }] = useUserContext();
    const { wishlistItemsCount } = useWishlistItemsCount();
    const { handleSignOut } = useAuthModal({});

    const wishlistLinkTitle = wishlistItemsCount
        ? t('myWishlistWithQuantity', { quantity: wishlistItemsCount })
        : t('myWishlist');

    return {
        handleSignOut,
        isShowAccountMenu: isSignedIn,
        wishlistLinkTitle,
    };
};
