import React, { ReactElement, useEffect } from 'react';
import { useTranslations } from 'next-intl';
import Confetti from 'react-dom-confetti';
import { StepEnum, type TNewsletterSetState } from '@/components/Newsletter/types';
import classes from './formSuccess.module.scss';

const FormSuccess = ({ setStep }: { setStep: TNewsletterSetState }): ReactElement => {
    const t = useTranslations('newsletter');

    const confettiConfig = {
        colors: ['#da2128', '#000'],
        dragFriction: 0.12,
        duration: 3000,
        elementCount: 70,
        height: '5px',
        spread: 360,
        stagger: 3,
        width: '5px',
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setStep({ currentStep: StepEnum.SUBSCRIBE });
        }, 10000);

        return () => clearTimeout(timer);
    }, [setStep]);

    return (
        <>
            <Confetti active={true} config={confettiConfig} />
            <div className={classes.messageComplete}>
                {t('completeMessage')}
                <br />
                {t('completeMessageAdditional')}
            </div>
        </>
    );
};

export default FormSuccess;
