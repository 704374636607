import React, { ReactElement } from 'react';
import { lazy } from '@loadable/component';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import Link from '@/components/Link';
import { setSearchParams } from '@/components/SearchBar/hooks/useSearchBar';
import { convertProductName } from '@/utils/Product';

import type { ISuggestedKeywordProps } from '@/components/SearchBar/types';
import classes from '../Suggestions/suggestionHighlight.module.scss';

const Highlighter = lazy(() => import('react-highlight-words'));

const SuggestedKeyword = ({
    fredHopperSuggestion,
    searchQueryValue,
    setIsSearchOpen,
}: ISuggestedKeywordProps): ReactElement => {
    const resourceUrl = useResourceUrl();

    const keywordSuggestion = fredHopperSuggestion.searchterm;
    const nextSearchParams = setSearchParams(keywordSuggestion, 'autosuggest_suggestion');

    return (
        <Link
            className={classes.suggestionLink}
            href={resourceUrl(`/catalogsearch/result/?${nextSearchParams}`)}
            onClick={() => setIsSearchOpen(false)}
            data-suggestion-item={true}
            data-suggestion-value={keywordSuggestion}
        >
            <Highlighter
                highlightClassName={classes.highlightClassName}
                unhighlightClassName={classes.unHighlightClassName}
                searchWords={[searchQueryValue]}
                autoEscape={true}
                textToHighlight={convertProductName(keywordSuggestion)}
            />
            <span className={classes.itemsCount}>{fredHopperSuggestion.nrResults}</span>
        </Link>
    );
};

SuggestedKeyword.displayName = 'SuggestedKeyword';

export default SuggestedKeyword;
