import React from 'react';
import clsx from 'clsx';
import { FiCheck as CheckIcon } from 'react-icons/fi';
import Message from '@/components/FormMessage';
import type { ICheckboxPropsWrapper } from '@/components/Checkbox/types';
import classes from '@/components/Checkbox/checkbox.module.scss';

const CheckboxWrapper = ({
    backgroundColor,
    children,
    count,
    error,
    id,
    isFilter,
    isRequired,
    isRound,
    label,
    message,
}: ICheckboxPropsWrapper) => {
    const choiceBoxClassName = clsx(
        isFilter ? classes.checkboxAsFilter : classes.checkboxIcon,
        backgroundColor && classes.withBackground,
        isRound && classes.isRound,
    );

    return (
        <span className={classes.checkbox}>
            {children}
            <label className={error ? classes.checkboxLabelError : classes.checkboxLabel} htmlFor={id}>
                <div className={classes.zeroWidthCheckboxWrapper}>
                    {/*Zero-width space character, used to align checkbox properly*/}
                    &#8203;
                    <span
                        className={choiceBoxClassName}
                        style={{
                            backgroundColor,
                            borderRadius: isRound ? '50%' : '',
                        }}
                    >
                        <CheckIcon />
                    </span>
                </div>
                {label}
                {count ? <span className={classes.count}>{count}</span> : null}
                {/*asterisk sometimes needs to be placed inside an actual message wrapper in the parent component,
           because of it's specific structure. @see newsletter.tsx as an example*/}
                {isRequired && typeof label === 'string' ? '*' : null}
            </label>
            {error || message ? (
                <Message error={error} className={classes.checkboxMessage}>
                    {message}
                </Message>
            ) : null}
        </span>
    );
};

export default CheckboxWrapper;
