import { useCallback } from 'react';
import { useResourceUrl } from '@/hooks/useResourceUrl';
import type { IUseSearchBar, IUseSearchBarProps, TSearchBarValues } from '../types';
import { useVeniaRouter } from '@/utils/veniaRouter';

export const setSearchParams = (searchValue: string, suggestionType: string, modifyQuery = false) => {
    const params = new URLSearchParams();

    if (searchValue) {
        if (modifyQuery) {
            params.set('qu', searchValue);
        } else {
            params.set('q', searchValue);
        }
    }

    params.set('ct', suggestionType);

    return `${params}`;
};

const initialValues = { search_query: '' };

export const useSearchBar = ({ isSearchOpen, setIsSearchOpen }: IUseSearchBarProps): IUseSearchBar => {
    const { push } = useVeniaRouter();
    const resourceUrl = useResourceUrl();

    const handleSubmit = useCallback(
        ({ search_query }: TSearchBarValues) => {
            if (typeof search_query !== 'string' || search_query.trim().length === 0) {
                return;
            }

            const searchParams = setSearchParams(search_query, 'regular_search');

            push(resourceUrl(`/catalogsearch/result/?${searchParams}`));

            // Always close search component on submit
            isSearchOpen ? setIsSearchOpen(false) : null;
        },
        [isSearchOpen, push, resourceUrl, setIsSearchOpen],
    );

    return {
        handleSubmit,
        initialValues,
    };
};
