import { useEffect } from 'react';
import { useCartContext } from '@/lib/context';
import { useQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import usePrevious from '@/hooks/usePrevious';
import { useEmarsysContext } from '@vaimo/emarsys-tracking';
import { useMonetateContext } from '@vaimo/monetate';
import type { ApolloError, DocumentNode } from '@apollo/client';
import type { ICart } from '@/types/cart';
import { useCleanUpCart } from '@/talons/CleanUpCart/useCleanUpCart';

interface IUseMiniCartDataProps {
    operation: DocumentNode;
    skipCondition?: boolean;
}

interface IUseMiniCartData {
    miniCartData: { cart: ICart };
    miniCartError?: ApolloError;
    miniCartLoading: boolean;
}

export const MINICART_ERROR_MESSAGE = "Can't assign cart to store in different website.";
export const ACTIVE_CART_ERROR_MESSAGE = "The cart isn't active.";

const useUpdateMiniCartData = ({ operation, skipCondition }: IUseMiniCartDataProps): IUseMiniCartData => {
    const [
        { cartId, isLoading },
        {
            actions: { setCartContent },
        },
    ] = useCartContext();
    const { cleanUpCart } = useCleanUpCart();

    const [
        ,
        {
            actions: { setCartData },
        },
    ] = useEmarsysContext();

    const [
        ,
        {
            actions: { setCartDetails: setCartDetailsToMonetate },
        },
    ] = useMonetateContext();

    const {
        data: miniCartData,
        error: miniCartError,
        loading: miniCartLoading,
    } = useQuery(operation, {
        context: {
            skipErrorToasts: true,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: skipCondition || isLoading || !cartId,
        variables: { cartId },
    });

    const previousMiniCartData = usePrevious(JSON.stringify(miniCartData));

    useEffect(() => {
        if (!miniCartData || previousMiniCartData === JSON.stringify(miniCartData)) {
            return;
        }

        setCartContent(miniCartData);
        setCartData(miniCartData);
        setCartDetailsToMonetate(miniCartData);
    }, [miniCartData, previousMiniCartData, setCartContent, setCartData, setCartDetailsToMonetate]);

    useEffect(() => {
        if (!miniCartError) return;

        if (!miniCartLoading && miniCartError.message === MINICART_ERROR_MESSAGE) {
            cleanUpCart();
        }
    }, [miniCartError, miniCartLoading]);

    return { miniCartData, miniCartError, miniCartLoading };
};

export default useUpdateMiniCartData;
