import { useMemo } from 'react';
import { useUserContext } from '@/lib/context';
import { useQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { GET_PRO_ACCOUNT_STATUS } from '../api/topBar.gql';
import type { GetProAccountStatusResponse, FormattedContent, UseTopBarOutput, UseTopBarProps } from '../types';

const formatCmsContent = (cmsContent?: string): FormattedContent => {
    if (!cmsContent) return null;

    const listItems = cmsContent.match(/&lt;li&gt;(.*)&lt;\/li&gt;/g);

    if (!listItems || listItems?.length === 1) {
        return cmsContent;
    }

    // Every <li> is converted into a separate html block
    return listItems?.map(
        (item) =>
            `<div data-content-type=\"html\" data-appearance=\"default\" data-element=\"main\">&lt;ul&gt;${item}&lt;\/ul&gt;</div>`,
    );
};

export const useTopBar = ({
    fallbackBlockContent,
    generalBlockContent,
    guestBlockContent,
    proBlockContent,
}: UseTopBarProps): UseTopBarOutput => {
    const [{ isSignedIn }] = useUserContext();

    const { data: accountData } = useQuery<GetProAccountStatusResponse>(GET_PRO_ACCOUNT_STATUS, {
        fetchPolicy: 'no-cache',
        skip: !isSignedIn,
    });

    const isActivePro = !!accountData?.customer.pro_account.discount_applicable;
    const isGeneral = isSignedIn && !isActivePro;
    const isGuest = !isSignedIn;

    const content = useMemo(() => {
        if (isActivePro) {
            return formatCmsContent(proBlockContent);
        }

        if (isGeneral) {
            return formatCmsContent(generalBlockContent);
        }

        if (isGuest) {
            return formatCmsContent(guestBlockContent);
        }

        return formatCmsContent(fallbackBlockContent);
    }, [
        fallbackBlockContent,
        generalBlockContent,
        guestBlockContent,
        isActivePro,
        isGeneral,
        isGuest,
        proBlockContent,
    ]);

    return {
        content,
    };
};
