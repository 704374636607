import { useTranslations } from 'next-intl';

interface useCurrentSearchStatusProps {
    status: string;
    value: string;
}

export const SEARCH_RESULT_STATUS = {
    empty: 'SEARCH EMPTY',
    error: 'SEARCH ERROR',
};

export const useCurrentSearchStatus = ({ status, value }: useCurrentSearchStatusProps): string | undefined => {
    const t = useTranslations('autocomplete');

    const MESSAGES = new Map([
        [SEARCH_RESULT_STATUS.error, t('error')],
        [SEARCH_RESULT_STATUS.empty, t('empty', { searchQueryValue: value })],
    ]);

    return MESSAGES.get(status);
};
