import React, { ReactElement } from 'react';
import { useField } from 'formik';

import Message from '@/components/FormMessage';
import Radio from './radio';

import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './radioGroup.module.scss';

interface IRadioGroupProps {
    classes?: {
        radioLabel?: string;
        root?: string;
    };
    disabled?: boolean;
    field: string;
    items: { value: string }[];
    message?: string;
}

const RadioGroup = ({ classes: propClasses, disabled, field, items, message }: IRadioGroupProps): ReactElement => {
    const [, meta] = useField(field);
    const error = meta.error;

    const classes = mergeClasses(defaultClasses, propClasses);

    const options = (
        <div aria-required={true}>
            {items?.map(({ value, ...item }) => (
                <Radio
                    disabled={disabled}
                    {...item}
                    classes={{
                        radioLabel: classes.radioLabel,
                        root: classes.radioContainer,
                    }}
                    key={value}
                    value={value}
                    id={value}
                    field={field}
                />
            ))}
        </div>
    );

    return (
        <>
            <div className={classes.root}>{options}</div>
            {error || message ? <Message error={error}>{message}</Message> : null}
        </>
    );
};

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
