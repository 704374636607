import { useState, useEffect, MutableRefObject } from 'react';

const useKeyPress = (targetKey: string, ref?: MutableRefObject<any>, shouldPreventDefault?: boolean) => {
    const [keyPressed, setKeyPressed] = useState<boolean>(false);

    useEffect(() => {
        const downHandler = (event: KeyboardEvent) => {
            if (event.key === targetKey) {
                if (shouldPreventDefault) event.preventDefault();
                setKeyPressed(true);
            }
        };

        const upHandler = (event: KeyboardEvent) => {
            if (event.key === targetKey) {
                if (shouldPreventDefault) event.preventDefault();
                setKeyPressed(false);
            }
        };

        const keyDownRef = ref?.current;
        const keyUpRef = ref?.current;

        if (keyDownRef) {
            keyDownRef.addEventListener('keydown', downHandler);
        }
        if (keyUpRef) {
            keyUpRef.addEventListener('keyup', upHandler);
        }

        return () => {
            if (keyDownRef) {
                keyDownRef?.removeEventListener('keydown', downHandler);
            }
            if (keyUpRef) {
                keyUpRef?.removeEventListener('keyup', upHandler);
            }
        };
    }, [ref, shouldPreventDefault, targetKey]);

    return keyPressed;
};

export default useKeyPress;
