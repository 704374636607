import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import QuantitySelectorSkeleton from '@/components/Quantity/Skeleton/quantitySelector';

import miniCartClasses from '@/components/MiniCart/styles/miniCart.module.scss';
import itemClasses from '@/components/MiniCart/modules/ProductList/styles/item.module.scss';

interface IMiniCartSkeletonProps {
    itemCount: number;
}

const MiniCartSkeleton = ({ itemCount }: IMiniCartSkeletonProps): ReactElement => (
    <>
        <div className={miniCartClasses.body}>
            {Array(itemCount)
                .fill(1)
                .slice(0, 6)
                .map((_, idx) => (
                    <div key={idx} className={itemClasses.root}>
                        {/* Product image */}
                        <div className={itemClasses.itemImage}>
                            <Skeleton height={100} width={100} />
                        </div>
                        <div className={itemClasses.itemContent}>
                            {/* Product name */}
                            <div className={itemClasses.itemName}>
                                <Skeleton height={18} width={200} />
                            </div>
                            {/* Product price */}
                            <div className={itemClasses.itemPrice}>
                                <Skeleton height={18} width={150} />
                            </div>
                            {/* Product color and size */}
                            <div style={{ display: 'grid', gap: '0.25rem' }}>
                                <Skeleton height={14} width={150} />
                                <Skeleton height={14} width={150} />
                            </div>
                            {/* Product quantity */}
                            <QuantitySelectorSkeleton />
                        </div>
                    </div>
                ))}
        </div>
        <div className={miniCartClasses.summary}>
            <div className={`${miniCartClasses.orderSummaryTotalRow} ${miniCartClasses.price}`}>
                <Skeleton height={21} width={80} />
                <Skeleton height={21} width={51} />
            </div>
            <div>
                <Skeleton height={45} width={368} />
            </div>
            <div>
                <Skeleton height={45} width={368} />
            </div>
        </div>
    </>
);

export default MiniCartSkeleton;
