import React, { KeyboardEvent, ReactElement, ReactNode } from 'react';
import { useTranslations } from 'next-intl';
import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './trigger.module.scss';

interface ITriggerProps {
    action: () => void;
    addLabel?: boolean | string;
    children: ReactNode;
    classes?: {
        [className: string]: string;
    };
}

const Trigger = (props: ITriggerProps): ReactElement => {
    const { action, addLabel, children, classes: propClasses, ...restProps } = props;
    const classes = mergeClasses(defaultClasses, propClasses);

    const tGlobal = useTranslations('global');
    const tSearchTrigger = useTranslations('searchTrigger');

    const arialabelClearLocalized = tSearchTrigger('clearSearchForm');
    const arialabelCloseLocalized = tGlobal('close');

    let resultedLabel = addLabel ? arialabelClearLocalized : arialabelCloseLocalized;

    const handleKeypress = () => {
        action();
        resultedLabel = '';
    };

    const changeAction = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
            action();
        }
    };

    return (
        <button
            className={classes.root}
            type="button"
            role="button"
            onClick={handleKeypress}
            onKeyDown={changeAction}
            aria-hidden="false"
            aria-label={resultedLabel}
            {...restProps}
        >
            {children}
        </button>
    );
};

Trigger.displayName = 'Trigger';

export default Trigger;
