import * as Yup from 'yup';

export const emailValidationSchema = Yup.string()
    .email('email.invalid')
    .matches(/@[^.]*\./, 'email.invalid')
    .required('email.required');

export const requiredFieldValidationSchema = Yup.string().required('isRequired');

export const passwordFullValidationSchema = Yup.string()
    .min(8, 'passwordHasLengthAtLeast')
    .matches(/[a-z]/, 'validatePassword')
    .matches(/[A-Z]/, 'validatePassword')
    .matches(/[0-9!@#$%^&*(),.?":{}|<>]/, 'validatePassword')
    .required('isRequired');
