import { useCallback } from 'react';
import { setSearchParams } from '@/components/SearchBar/hooks/useSearchBar';
import type { IUseSuggestedCategory, IUseSuggestedCategoryProps } from '@/components/SearchBar/types';

export const useSuggestedCategory = ({
    categoryUrl,
    onClick,
    searchValue,
}: IUseSuggestedCategoryProps): IUseSuggestedCategory => {
    const nextSearchParams = setSearchParams(searchValue, 'autosuggest_category', true);
    const destination = `/${categoryUrl}?${nextSearchParams}`;

    const handleClick = useCallback(() => {
        if (typeof onClick === 'function') {
            onClick();
        }
    }, [onClick]);

    return {
        destination,
        handleClick,
    };
};
