import { useMutation } from '@apollo/client';
import { useFormSubmit } from '@vaimo/google-tag-manager';
import subscribeNewsletterOperations from '@/components/Newsletter/subscribeNewsletter.gql';
import { trimInput } from '@/components/TextInput';
import { StepEnum, type TNewsletterSetState } from '@/components/Newsletter/types';
import type { IFormUpdateFields, IUseFormUpdate } from '@/components/Newsletter/FormUpdate/types';

export const useFormUpdate = ({ setStep }: { setStep: TNewsletterSetState }): IUseFormUpdate => {
    const [updateNewsletter, { error }] = useMutation(subscribeNewsletterOperations.mutations.updateNewsletterMutation);
    const { submitFormToDataLayer } = useFormSubmit();
    const handleSubmit = async ({
        ageGroup,
        context,
        countryId,
        email,
        firstName,
        genderId,
        lastName,
        sports,
    }: IFormUpdateFields) => {
        try {
            await updateNewsletter({
                context,
                variables: {
                    ageGroup,
                    countryId,
                    email: trimInput(email),
                    firstName: trimInput(firstName),
                    genderId,
                    lastName: trimInput(lastName),
                    sports: sports.join('|'),
                },
            });

            setStep({ currentStep: StepEnum.COMPLETE });
            submitFormToDataLayer({ formName: 'newsletter' });
        } catch (err) {
            if (process.env.NODE_ENV !== 'production') {
                console.log(err);
            }
        }
    };

    return {
        error,
        handleSubmit,
    };
};
