'use client';

import React, { ReactElement, ReactNode, useContext } from 'react';
import { useAppContext } from '@/lib/context';
import { HeaderContext } from '@/components/Header/context/headerContext';
import { TOP_HEADER_REF } from '@/constants/refIds';

import clsx from 'clsx';
import classes from './topHeaderWrapper.module.scss';

const TopHeaderWrapper = ({ children }: { children: ReactNode }): ReactElement => {
    const [{ isSearchOpen }] = useAppContext();
    const { isHeaderVisible } = useContext(HeaderContext);

    return (
        <section
            className={clsx(
                'general-top-header',
                classes.infoPanelWrapper,
                isSearchOpen && classes.infoPanelWrapperSearchOpen,
                !isHeaderVisible && classes.infoPanelWrapperInvisible,
            )}
            id={TOP_HEADER_REF}
            data-mt-type="top-header-wrapper"
        >
            {children}
        </section>
    );
};

TopHeaderWrapper.displayName = 'TopHeaderWrapper';

export default TopHeaderWrapper;
