export const hasContent = (arr: any) => Array.isArray(arr) && !!arr.length;

export const withContentOrNull = (arr: any): any[] | null => (hasContent(arr) ? arr : null);

export const prependElementsByIndex = (collection: any[], startIndex: number, deleteCount = 1) => {
    const clonedCollection = [...collection];
    clonedCollection.unshift(...clonedCollection.splice(startIndex, deleteCount));

    return clonedCollection;
};
