import { useQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { useUserContext } from '@/lib/context';

import { GET_WISHLIST_ITEMS_COUNT } from '../api/wishlistItemsCount.gql';
import type { IGetWishlistItemsCountResponse, IUseWishlistItemsCount } from '../types';

export const useWishlistItemsCount = (): IUseWishlistItemsCount => {
    const [{ isSignedIn }] = useUserContext();

    const { data } = useQuery<IGetWishlistItemsCountResponse>(GET_WISHLIST_ITEMS_COUNT, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        skip: !isSignedIn,
    });

    const wishlistItemsCount: number = data?.customer.wishlists[0]?.items_count || 0;

    return {
        wishlistItemsCount,
    };
};
