import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';

import SuggestedProduct from './suggestedProduct';

import type { ISuggestedProductsProps } from '@/components/SearchBar/types';
import classes from './suggestedProducts.module.scss';

const SuggestedProducts = ({
    fredHopperProducts,
    searchQueryValue,
    setIsSearchOpen,
}: ISuggestedProductsProps): ReactElement => {
    const t = useTranslations('searchBar');

    const PRODUCTS_QUANTITY_LIMIT = 4;
    const items = fredHopperProducts.map((product, index) => {
        const item = (
            <li key={product.secondId} className={classes.suggestionItem}>
                <SuggestedProduct
                    searchQueryValue={searchQueryValue}
                    fredHopperProduct={product}
                    setIsSearchOpen={setIsSearchOpen}
                />
            </li>
        );

        return index < PRODUCTS_QUANTITY_LIMIT ? item : null;
    });

    return (
        <>
            <h2 className={classes.suggestionsListHeading}>{t('productsHeading')}</h2>
            <ul className={classes.suggestionsList}>{items}</ul>
        </>
    );
};

SuggestedProducts.displayName = 'SuggestedProducts';

export default SuggestedProducts;
