import React, { useMemo } from 'react';

import Item from './item';

import type { IProductListProps } from './types';
import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './styles/productList.module.scss';

const ProductList = ({
    classes: propClasses,
    closeMiniCart,
    configurableThumbnailSource,
    confirmRemoveItemId,
    handleCancelRemoveItem,
    handleConfirmRemoveItem,
    handleRemoveItem,
    items,
    setIsUpdatingQuantity,
}: IProductListProps) => {
    const classes = mergeClasses(defaultClasses, propClasses);

    const cartItems = useMemo(() => {
        if (items) {
            return items.map((item) => {
                const boundCancel = () => handleCancelRemoveItem();
                const boundDelete = () => handleConfirmRemoveItem(item.id);
                const isConfirmingRemove = confirmRemoveItemId === item.id;

                return (
                    <Item
                        key={item.id}
                        {...item}
                        closeMiniCart={closeMiniCart}
                        handleRemoveItem={handleRemoveItem}
                        configurableThumbnailSource={configurableThumbnailSource}
                        handleConfirmRemoveItem={boundDelete}
                        handleCancelRemoveItem={boundCancel}
                        isConfirmingRemove={isConfirmingRemove}
                        setIsUpdatingQuantity={setIsUpdatingQuantity}
                    />
                );
            });
        }
    }, [
        items,
        confirmRemoveItemId,
        closeMiniCart,
        handleRemoveItem,
        configurableThumbnailSource,
        setIsUpdatingQuantity,
        handleCancelRemoveItem,
        handleConfirmRemoveItem,
    ]);

    return <div className={classes.root}>{cartItems}</div>;
};

export default ProductList;
