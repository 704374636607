import React, { ChangeEvent, ReactElement } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import CheckboxWrapper from './modules/checkboxWrapper';
import { ICheckboxProps } from './types';
import classes from './checkbox.module.scss';

export const Checkbox = ({
    backgroundColor,
    count,
    disabled,
    field,
    id,
    isFilter,
    isRequired,
    isRound,
    label,
    message,
    onChange,
    ...rest
}: ICheckboxProps): ReactElement => {
    const [, meta, helpers] = useField(field);
    const { validateField } = useFormikContext();

    return (
        <CheckboxWrapper
            error={meta.error}
            id={id}
            isRequired={isRequired}
            backgroundColor={backgroundColor}
            count={count}
            isFilter={isFilter}
            isRound={isRound}
            label={label}
            message={message}
        >
            <Field
                {...rest}
                aria-required={isRequired}
                className={classes.checkboxInput}
                disabled={disabled}
                onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                    if (onChange) {
                        onChange(e);
                    } else {
                        await helpers.setValue(e.target.checked);
                        await validateField(field);
                    }
                }}
                field={field}
                id={id}
                name={field}
                type={'checkbox'}
            />
        </CheckboxWrapper>
    );
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
