import { gql } from '@apollo/client';

export const GET_CUSTOMER = gql`
    query GetCustomerForLeftNav {
        customer {
            id
            email
            firstname
            lastname
            is_subscribed
        }
    }
`;

export const NavigationConfigFragment = gql`
    fragment NavigationConfigFragment on StoreConfig {
        rootCategoryId: root_category_id
    }
`;

export default {
    getCustomerQuery: GET_CUSTOMER,
};
