import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    ageGroup: Yup.string(),
    countryId: Yup.number().required('isRequired'),
    email: Yup.string().email().required('isRequired'),
    firstName: Yup.string(),
    genderId: Yup.string().required('isRequired'),
    lastName: Yup.string(),
    sports: Yup.array(),
});
