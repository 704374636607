import React, { ReactElement, useEffect } from 'react';
import { use100vh } from 'react-div-100vh';
import { useAutocomplete } from './hooks/useAutocomplete';
import { useCurrentSearchStatus } from '@/talons/CurrentStatus/useCurrentSearchStatus';
import Suggestions from '../Suggestions';

import type { IAutocompleteProps } from '@/components/SearchBar/types';
import classes from './autocomplete.module.scss';

const Autocomplete = ({ isSearchOpen, setIsSearchOpen, setShouldDisplayResult }: IAutocompleteProps): ReactElement => {
    const fullWindowHeight = use100vh();

    const {
        isSuggestionsFetchRunning,
        messageType,
        searchQueryValue,
        shouldDisplayResult,
        suggestedCategories,
        suggestedKeywords,
        suggestedProducts,
    } = useAutocomplete({ isSearchOpen });

    //
    //  Passing necessary values to SearchField Component
    //  ---------------------------------------------

    // We pass this value to the SearchField component outside, and based on that assign additional data (id, class) to fetched suggestions elements.
    // Needed for keyboard navigation.
    useEffect(() => {
        setShouldDisplayResult(shouldDisplayResult);
    }, [setShouldDisplayResult, shouldDisplayResult]);

    //
    //  Autocomplete messages. We only need them if there are no search results.
    //  ---------------------------------------------
    const message = useCurrentSearchStatus({ status: messageType, value: searchQueryValue });
    const messageElement = message && <div className={classes.message}>{message}</div>;

    //
    //  Variables
    //  ---------------------------------------------
    const rootClassName = isSearchOpen ? classes.rootVisible : classes.rootHidden;

    return (
        <aside
            className={rootClassName}
            id="autocomplete"
            // @todo Hold header's height as a global state, and derive value from there ( 72px below is header's height )
            style={{
                maxHeight: !messageType && !isSuggestionsFetchRunning ? Number(fullWindowHeight) - 72 : undefined,
            }}
        >
            {messageElement}
            <Suggestions
                setIsSearchOpen={setIsSearchOpen}
                searchQueryValue={searchQueryValue}
                fredHopperSuggestions={suggestedKeywords}
                fredHopperCategories={suggestedCategories}
                fredHopperProducts={suggestedProducts}
                isSuggestionsFetchRunning={isSuggestionsFetchRunning}
            />
        </aside>
    );
};

Autocomplete.displayName = 'Autocomplete';

export default Autocomplete;
