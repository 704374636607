'use client';

import { MouseEvent, useCallback } from 'react';
import { useVeniaRouter } from '@/utils/veniaRouter';

const useCmsLinkClick = () => {
    const router = useVeniaRouter();

    const handleCmsLinkClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            event.preventDefault();

            const linkTarget = (event.target as HTMLElement).getAttribute('href');

            if (!linkTarget) {
                return;
            }

            if (linkTarget.startsWith('http')) {
                globalThis.window.location.href = linkTarget;

                return;
            }

            router.push(linkTarget.startsWith('/') ? linkTarget : `/${linkTarget}`);
        },
        [router],
    );

    return {
        handleCmsLinkClick,
    };
};

export default useCmsLinkClick;
