import { useMemo } from 'react';
import configuredVariant from '@/utils/Product/configuredVariant';
import type { ConfigurableCartItem } from '@/types/product';

const OUT_OF_STOCK_CODE = 'OUT_OF_STOCK';
const CONFIGURABLE_CART_ITEM = 'ConfigurableCartItem';

interface IUseStockStatusMessage {
    cartItems?: ConfigurableCartItem[];
}

export const useStockStatusMessage = ({ cartItems }: IUseStockStatusMessage): { hasOutOfStockItem: boolean } => {
    const hasOutOfStockItem = useMemo(() => {
        if (!cartItems?.length) return false;

        return cartItems.some((cartItem) => {
            const { __typename, configurable_options: options, product } = cartItem;
            const { stock_status: stockStatus } = product;
            const isConfigurableCartItem = __typename === CONFIGURABLE_CART_ITEM;

            return (
                stockStatus === OUT_OF_STOCK_CODE || (isConfigurableCartItem && !configuredVariant(options, product))
            );
        });
    }, [cartItems?.length]);

    return { hasOutOfStockItem };
};
