'use client';

import React, { ReactElement } from 'react';
import LogoSportswear from '@/icons/logo/logo-sportswear';
import LogoWorkwear from '@/icons/logo/logo-workwear';
import { useAppContext } from '@/lib/context';
import useIsDesktop from '@/hooks/useIsDesktop';

interface IRenderedIconProps {
    className?: string;
    isSportswearTheme: boolean;
}

const RenderedIcon = ({ isSportswearTheme, ...rest }: IRenderedIconProps): ReactElement | null => {
    const [{ isSearchOpen }] = useAppContext();
    const isDesktop = useIsDesktop();

    if (isSearchOpen && !isDesktop) {
        return null;
    }

    return isSportswearTheme ? <LogoSportswear {...rest} /> : <LogoWorkwear {...rest} />;
};

export default RenderedIcon;
