import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';
import { useStockStatusMessage } from '@/talons/StockStatusMessage/useStockStatusMessage';
import Notification from '@/components/Notification';
import type { ConfigurableCartItem } from '@/types/product';

interface IStockStatusMessage {
    cartItems?: ConfigurableCartItem[];
    message?: string;
    messageId?: string;
}

const StockStatusMessage = ({ cartItems, messageId = 'message' }: IStockStatusMessage): ReactElement | null => {
    const { hasOutOfStockItem } = useStockStatusMessage({ cartItems });
    const t = useTranslations('stockStatusMessage');

    if (!hasOutOfStockItem) return null;

    return <Notification variant="error">{t(messageId)}</Notification>;
};

StockStatusMessage.displayName = 'StockStatusMessage';

export default StockStatusMessage;
