'use client';

import React, { ReactElement, Suspense } from 'react';
import { use100vh } from 'react-div-100vh';
import { lazy } from '@loadable/component';
import { useNavigation } from '@/talons/Navigation/useNavigation';
import LoadingIndicator from '@/components/LoadingIndicator';

import type { INavigationProps } from './types';
import classes from './navigation.module.scss';

const AuthModal = lazy(() => import('@/components/AuthModal'));
const AuthBar = lazy(() => import('@/components/AuthBar'));
const CategoryTree = lazy(() => import('@/components/CategoryTree'));
const NavHeader = lazy(() => import('./navHeader'));

const Navigation = ({ megaMenuData, menuLinks, rootCategoryId }: INavigationProps): ReactElement | null => {
    const {
        categoryId,
        categoryTitle,
        changeCurrentCategory,
        handleBack,
        handleClose,
        hasModal,
        isOpen,
        isTopLevel,
        parentCategoryIdsArray,
        showCreateAccount,
        showForgotPassword,
        showMainMenu,
        showMyAccount,
        showSignIn,
        showStoreSwitcher,
        view,
    } = useNavigation({ rootCategoryId });
    const fullWindowHeight = use100vh();

    const shouldRenderMegaMenu = isOpen;

    if (!shouldRenderMegaMenu) {
        return null;
    }

    const rootClassName = isOpen ? classes.root_open : classes.root;
    const modalClassName = hasModal ? classes.modal_open : classes.modal;
    const bodyClassName = hasModal ? classes.body_masked : classes.body;

    const authModal = hasModal ? (
        <AuthModal
            closeDrawer={handleClose}
            showCreateAccount={showCreateAccount}
            showForgotPassword={showForgotPassword}
            showMainMenu={showMainMenu}
            showMyAccount={showMyAccount}
            showSignIn={showSignIn}
            view={view}
        />
    ) : null;

    return (
        <aside className={rootClassName} style={{ height: fullWindowHeight || undefined }}>
            <Suspense fallback={<LoadingIndicator />}>
                <header className={classes.header}>
                    <NavHeader
                        isTopLevel={isTopLevel}
                        onBack={handleBack}
                        onClose={handleClose}
                        view={view}
                        categoryTitle={categoryTitle}
                    />
                </header>
                <div className={bodyClassName}>
                    <CategoryTree
                        megaMenuData={megaMenuData}
                        menuLinks={menuLinks}
                        isTopLevel={isTopLevel}
                        categoryId={categoryId}
                        onNavigate={handleClose}
                        showStoreSwitcher={showStoreSwitcher}
                        changeCurrentCategory={changeCurrentCategory}
                        parentCategoryIdsArray={parentCategoryIdsArray}
                    />
                </div>
                <div className={classes.footer}>
                    <AuthBar
                        disabled={hasModal}
                        showMyAccount={showMyAccount}
                        showCreateAccount={showCreateAccount}
                        showSignIn={showSignIn}
                    />
                </div>
                <div className={modalClassName}>{authModal}</div>
            </Suspense>
        </aside>
    );
};

Navigation.displayName = 'Navigation';

export default Navigation;
