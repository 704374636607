'use client';

import React, { ReactElement } from 'react';
import { useAppContext } from '@/lib/context';

import MegaMenuItem from './megaMenuItem';

import type { IMegaMenuProps } from './types';
import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './megaMenu.module.scss';

const MegaMenu = ({ classes: propsClasses, megaMenuData }: IMegaMenuProps): ReactElement => {
    const classes = mergeClasses(defaultClasses, propsClasses);
    const [{ isSearchOpen }] = useAppContext();

    const megaMenuClassName = isSearchOpen ? classes.visuallyHidden : classes.root;

    const items = megaMenuData?.children
        ? megaMenuData.children.map((category) => <MegaMenuItem category={category} key={category.id} />)
        : null;

    return (
        <nav className={megaMenuClassName} role="navigation" data-mt-type="header-navigation">
            {items}
        </nav>
    );
};

MegaMenu.displayName = 'MegaMenu';

export default MegaMenu;
