import { gql } from '@apollo/client';

export const GET_RECAPTCHA_CONFIG = gql`
    query getReCaptchaConfig {
        reCaptchaConfig {
            customer_create {
                type
                website_key
            }
            customer_login {
                type
                website_key
            }
            customer_forgot_password {
                type
                website_key
            }
            newsletter {
                type
                website_key
            }
        }
    }
`;
