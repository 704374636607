import React, { ReactElement } from 'react';
import { ICheckboxProps } from './types';
import CheckboxWrapper from './modules/checkboxWrapper';
import classes from './checkbox.module.scss';

/**
 * Checkbox element without formik functionality
 */

const CheckboxSimple = ({
    backgroundColor,
    count,
    disabled,
    field,
    id,
    isFilter,
    isRequired,
    isRound,
    label,
    message,
    onChange,
    value,
    ...rest
}: ICheckboxProps): ReactElement => {
    return (
        <CheckboxWrapper
            id={id}
            isRequired={isRequired}
            backgroundColor={backgroundColor}
            count={count}
            isFilter={isFilter}
            isRound={isRound}
            message={message}
            label={label}
        >
            <input
                {...rest}
                aria-required={isRequired}
                className={classes.checkboxInput}
                disabled={disabled}
                id={id}
                name={field}
                type={'checkbox'}
                value={value}
                onChange={onChange}
            />
        </CheckboxWrapper>
    );
};

export default CheckboxSimple;
