import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';
import SuggestedKeyword from './suggestedKeyword';
import useIsDesktop from '@/hooks/useIsDesktop';

import type { ISuggestedKeywordsProps } from '@/components/SearchBar/types';
import classes from './suggestedKeywords.module.scss';

const SuggestedKeywords = ({
    fredHopperSuggestions,
    searchQueryValue,
    setIsSearchOpen,
}: ISuggestedKeywordsProps): ReactElement => {
    const isDesktop = useIsDesktop();
    const t = useTranslations('searchBar');

    const SUGGESTIONS_QUANTITY_LIMIT = isDesktop ? 8 : 4;
    const items = fredHopperSuggestions.map((suggestion, index) => {
        const item = (
            <li className={classes.suggestionItem} key={`${suggestion.searchterm.replace(/\\s/g, '-')}-${index}`}>
                <SuggestedKeyword
                    fredHopperSuggestion={suggestion}
                    searchQueryValue={searchQueryValue}
                    setIsSearchOpen={setIsSearchOpen}
                />
            </li>
        );

        return index < SUGGESTIONS_QUANTITY_LIMIT ? item : null;
    });

    return (
        <>
            <h2 className={classes.suggestionsListHeading}>{t('suggestionsHeading')}</h2>
            <ul className={classes.suggestionsList}>{items}</ul>
        </>
    );
};

SuggestedKeywords.displayName = 'SuggestedKeywords';

export default SuggestedKeywords;
