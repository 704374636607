'use client';

import React from 'react';
import { useAppContext } from '@/lib/context';

import Mask from '@/components/Mask';

const SearchMask = () => {
    const [
        { isSearchOpen },
        {
            actions: { setIsSearchOpen },
        },
    ] = useAppContext();

    return (
        <Mask
            isActive={isSearchOpen}
            dismiss={() => {
                setIsSearchOpen(false);
            }}
        />
    );
};

SearchMask.displayName = 'SearchMask';

export default SearchMask;
