import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';

import classes from './quantitySelector.module.scss';

const QuantitySelector = (): ReactElement => (
    <div className={classes.root}>
        <Skeleton height={32} width={32} />
        <Skeleton height={16} width={16} />
        <Skeleton height={32} width={32} />
    </div>
);

QuantitySelector.displayName = 'QuantitySelectorSkeleton';

export default QuantitySelector;
