'use client';

import React, { ReactElement } from 'react';
import { FiSearch as SearchIcon } from 'react-icons/fi';
import { useTranslations } from 'next-intl';

import { useSearchField } from './hooks/useSearchField';

import SearchInput from '@/components/SearchInput';
import Button from '@/components/Button';

import type { ISearchFieldProps } from '@/components/SearchBar/types';
import classes from './searchField.module.scss';

const SearchField = ({
    field,
    isSearchOpen,
    setIsSearchOpen,
    shouldDisplayResult,
}: ISearchFieldProps): ReactElement => {
    const tSearchField = useTranslations('searchField');
    const tSearchTrigger = useTranslations('searchTrigger');

    const { handleEnter, inputRef, isMobile, resetForm, searchInputLabel, submitAndCloseSearch, value } =
        useSearchField({
            isSearchOpen,
            setIsSearchOpen,
            shouldDisplayResult,
        });

    const searchTitle = tSearchField('searchTitle');
    const clearSearchLabel = tSearchTrigger('clearSearchForm');

    return (
        <div className={classes.searchFieldWrapper}>
            <div className={classes.searchInputWrapper}>
                <Button
                    variant="trigger"
                    onClick={submitAndCloseSearch}
                    className={classes.searchTrigger}
                    type="submit"
                    role="button"
                    aria-label={'Submit search'}
                    title={tSearchTrigger('submitSearch')}
                >
                    <SearchIcon size={24} />
                </Button>
                <SearchInput
                    className={classes.searchInput}
                    onFocus={() => {
                        !isMobile && setIsSearchOpen(true);
                    }}
                    field={field}
                    label={searchInputLabel}
                    forwardedRef={inputRef}
                    maxLength="2048"
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    role="combobox"
                    spellCheck="false"
                    title={searchTitle}
                    aria-controls="autocomplete"
                    aria-expanded={isSearchOpen}
                    aria-label={searchTitle}
                    aria-required={false}
                    onKeyUp={(e: any) => handleEnter(e)}
                />
                {value && (
                    <Button
                        variant="link"
                        onClick={resetForm}
                        className={classes.clearButton}
                        type="button"
                        role="button"
                        aria-disabled={!value}
                        aria-label={clearSearchLabel}
                        title={clearSearchLabel}
                    >
                        {tSearchTrigger('clearSearchForm')}
                    </Button>
                )}
            </div>
        </div>
    );
};

SearchField.displayName = 'SearchField';

export default SearchField;
