'use client';

import React, { ReactElement } from 'react';
import { useSubscribeNewsletter } from './useSubscribeNewsletter';
import FormSuccess from '@/components/Newsletter/FormSuccess';
import FormSubscribe from '@/components/Newsletter/FormSubscribe';
import FormUpdate from '@/components/Newsletter/FormUpdate';
import { StepEnum } from '@/components/Newsletter/types';
import classes from './newsletter.module.scss';

const Newsletter = (): ReactElement => {
    const { setState, state } = useSubscribeNewsletter();

    const formContent = () => {
        switch (state.currentStep) {
            case StepEnum.SUBSCRIBE:
                return <FormSubscribe setStep={setState} />;
            case StepEnum.UPDATE:
                return <FormUpdate setStep={setState} data={state.data} email={state.email as string} />;
            case StepEnum.COMPLETE:
                return <FormSuccess setStep={setState} />;
            default:
                return <FormSubscribe setStep={setState} />;
        }
    };

    return (
        <div className={classes.wrapper} data-mt-type="newsletter-wrapper">
            <div className={classes.container}>{formContent()}</div>
        </div>
    );
};

export default Newsletter;
