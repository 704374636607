import { useAppContext } from '@/lib/context';
import { useIsTaxIncludedInPrice, type GetPriceWithOrWithoutTax } from '@/hooks/useIsTaxIncludedInPrice';
import configuredVariant from '@/utils/Product/configuredVariant';
import { hasContent } from '@/utils/Array';
import { ConfigurableCartItem } from '@/types/product';
import { isDonationProduct } from '@vaimo/donation-products';

import type { IFlatProduct, IUseProduct, IUseProductProps } from '../types';

const COLOR_ATTRIBUTE = 'color';
const OUT_OF_STOCK_CODE = 'OUT_OF_STOCK';
const CONFIGURABLE_PRODUCT = 'ConfigurableProduct';

export const flattenProduct = (
    item: ConfigurableCartItem,
    configurableThumbnailSource: string,
    getPriceWithOrWithoutTax: GetPriceWithOrWithoutTax,
): IFlatProduct => {
    const { configurable_options: options = [], prices, product, quantity } = item;

    const configured_variant = configuredVariant(options, product);

    const {
        __typename,
        configurable_options: productOptions,
        name,
        price,
        small_image,
        stock_status,
        url_key: urlKey,
        url_suffix: urlSuffix,
    } = product;

    const isConfigurableProduct = __typename === CONFIGURABLE_PRODUCT;
    const isOutOfStock = stock_status === OUT_OF_STOCK_CODE || (isConfigurableProduct && !configured_variant);

    const { url: image } =
        configurableThumbnailSource === 'itself' && configured_variant ? configured_variant.small_image : small_image;

    const { row_total, row_total_including_tax } = prices;
    const currentPrice =
        getPriceWithOrWithoutTax({ priceExclTax: row_total, priceInclTax: row_total_including_tax }).value / quantity;
    const { regularPrice } = price;
    const { currency, value } = regularPrice?.amount ?? {};

    const productPrices = isDonationProduct(product)
        ? {
              currency: row_total_including_tax?.currency,
              hasDiscount: false,
              maxPrice: row_total_including_tax?.value,
              minPrice: row_total_including_tax?.value,
          }
        : {
              currency,
              hasDiscount: currentPrice < value,
              maxPrice: value,
              minPrice: Math.min(currentPrice, value),
          };

    const colorIndex = hasContent(productOptions)
        ? options.find(({ configurable_product_option_uid }) =>
              productOptions.some(
                  ({ attribute_code, uid }) =>
                      configurable_product_option_uid === uid && attribute_code === COLOR_ATTRIBUTE,
              ),
          )?.value_id
        : null;

    const destination = colorIndex ? `/${urlKey}${urlSuffix}?color=${colorIndex}` : `/${urlKey}${urlSuffix}`;

    return {
        __typename,
        destination,
        image,
        isOutOfStock,
        name,
        options,
        productPrices,
        quantity,
        urlKey,
        urlSuffix: urlSuffix ?? '',
    };
};

export const useProduct = ({ item }: IUseProductProps): IUseProduct => {
    const [{ storeConfig }] = useAppContext();

    const { getPriceWithOrWithoutTax } = useIsTaxIncludedInPrice();
    const { configurableThumbnailSource } = storeConfig;

    const flatProduct = flattenProduct(item, configurableThumbnailSource, getPriceWithOrWithoutTax);
    const isDonationProductType = isDonationProduct(flatProduct);

    return {
        isDonationProductType,
        product: flatProduct,
    };
};
