import { gql } from '@apollo/client';

// We are pulling per store specific fredhopper search path.
// Currency doesn't come with fredhopper, so we are including it in the call, and using in suggestedProduct.js

export const GET_ADDITIONAL_INFORMATION = gql`
    query getAdditionalInformation {
        searchSuggestBasePath {
            result
        }
        currency {
            available_currency_codes
            default_display_currency_code
        }
    }
`;
