'use client';

import { useCallback, useState, useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { useMutation } from '@apollo/client';
import { useParams, usePathname } from 'next/navigation';
import useTakeMeHome from '@/hooks/useTakeMeHome';
import { toastApi } from '@/lib/context/toasts';
import { ToastEnum } from '@/lib/context/toasts/toastApi';
import subscribeNewsletterOperations from './subscribeNewsletter.gql';
import { type INewsLetterState, type IUseSubscribeNewsletter, StepEnum } from '@/components/Newsletter/types';

export const useSubscribeNewsletter = (): IUseSubscribeNewsletter => {
    const SUBSCRIBED_STATUS = 'SUBSCRIBED';

    const t = useTranslations('confirmNewsletterPageToast');

    const { code, id } = useParams();
    const pathname = usePathname();
    const [state, setState] = useState<INewsLetterState>({
        currentStep: StepEnum.SUBSCRIBE,
    });
    const takeMeHome = useTakeMeHome();

    const [confirmNewsletter, { error: confirmationError }] = useMutation(
        subscribeNewsletterOperations.mutations.confirmNewsletterSubscriptionMutation,
    );

    const handleConfirmation = useCallback(
        async ({ code, id }: { code: string; id: string }) => {
            try {
                const confirmationResponse = await confirmNewsletter({
                    variables: { code, id },
                });

                if (confirmationResponse.data.confirmSubscriber.status === SUBSCRIBED_STATUS) {
                    takeMeHome();
                }

                toastApi.add({
                    dismissible: true,
                    message: t('success'),
                    variant: ToastEnum.success,
                });
            } catch (err) {
                if (process.env.NODE_ENV !== 'production') {
                    console.log(err);
                }

                toastApi.add({
                    dismissible: true,
                    message: confirmationError?.message || t('error'),
                    variant: ToastEnum.error,
                });
            }
        },
        [confirmNewsletter, confirmationError?.message, t, takeMeHome],
    );

    useEffect(() => {
        if (id && code) {
            handleConfirmation({ code, id } as { code: string; id: string });
        }
    }, [id, code, handleConfirmation]);

    useEffect(() => {
        if (state.currentStep !== StepEnum.SUBSCRIBE) {
            setState({
                currentStep: StepEnum.SUBSCRIBE,
            });
        }
    }, [pathname]);

    return {
        setState,
        state,
    };
};
