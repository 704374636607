import React, { forwardRef } from 'react';
import { IconProps } from '../types';

const LogoSportswear = forwardRef<SVGSVGElement, IconProps>(({ height = 16, width = 16, ...props }, forwardedRef) => (
    <svg
        height={height}
        viewBox="0 0 85 44"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
    >
        <g fillRule="evenodd" transform="translate(.1614 .757067)">
            <path d="m80.5465976 7.64813495c2.1122757 0 3.8257492-1.71045873 3.8257492-3.82200004 0-2.11567618-1.7134735-3.82613491-3.8257492-3.82613491-2.1136508 0-3.8298747 1.71045873-3.8298747 3.82613491 0 2.11154131 1.7162239 3.82200004 3.8298747 3.82200004m-2.9497599-3.80821713c0-1.65119223 1.3394248-2.98951247 2.9951409-2.98951247 1.6488401 0 2.9868897 1.33832024 2.9868897 2.98951247 0 1.64843564-1.3380496 2.98813418-2.9868897 2.98813418-1.6557161 0-2.9951409-1.33969854-2.9951409-2.98813418" />
            <path d="m80.0541459 3.72855193h.6834642c.3217919 0 .5528221-.19020411.5528221-.5016978 0-.30735882-.2076521-.50445439-.643584-.50445439h-.5927023zm-.6298322-1.56022504h1.3944319c.7109678 0 1.1276472.42451353 1.1276472 1.01166535 0 .74841184-.5019405.92483305-.6628365.98409954l.7453473 1.34796828h-.7260948l-.6463343-1.23081357h-.6023286v1.23081357h-.6298322z" />
            <path d="m42.633038 3.84322572h4.9864006v13.28672208h12.3889916v-13.28672208h12.9500648v38.72858968h-12.9968209v-13.5485974h-12.3862412v13.5485974h-12.4646265z" />
            <path d="m30.3268322 42.5722288h-4.9877758v-13.286722h-12.3889916v13.286722h-12.9500648v-38.7285896h12.9940705v13.5485973h12.3876164v-13.5485973h12.5058819z" />
        </g>
    </svg>
));

LogoSportswear.displayName = 'LogoSportswear';

export default LogoSportswear;
