import React, { ReactElement } from 'react';
import { FiMinus as MinusIcon, FiPlus as PlusIcon } from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import { Field, Form, Formik } from 'formik';
import { useQuantity, QUANTITY_FIELD } from '@/talons/Quantity/useQuantity';
import FormError from '@/components/FormError';
import Button from '@/components/Button';

import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './quantity.module.scss';

interface IQuantityProps {
    classes?: {
        [className: string]: string;
    };
    handleDialogOpen?: (itemId: string) => void;
    initialValue: number;
    isDisabled?: boolean;
    isRemovingCanceled?: boolean;
    itemId: string;
    message?: string | null;
    min?: number;
    onChange?: (value: number) => void;
    setIsRemovingCanceled?: (value: boolean) => void;
}

export const QuantityFields = ({
    classes: propsClasses,
    handleDialogOpen,
    initialValue,
    isDisabled,
    isRemovingCanceled,
    itemId,
    message,
    min = 0,
    onChange = () => {},
    setIsRemovingCanceled,
}: IQuantityProps): ReactElement => {
    const tGlobal = useTranslations('global');
    const tQuantity = useTranslations('quantity');

    const { handleBlur, handleDecrement, handleIncrement, isDecrementDisabled, isIncrementDisabled, maskInput } =
        useQuantity({
            handleDialogOpen,
            initialValue,
            isRemovingCanceled,
            itemId,
            min,
            onChange,
            setIsRemovingCanceled,
        });

    const classes = mergeClasses(defaultClasses, propsClasses);

    const decreaseButtonTitle = tQuantity('buttonDecrement');
    const increaseButtonTitle = tQuantity('buttonIncrement');

    const isDecrementButtonDisabled = isDisabled || isDecrementDisabled;
    const isIncrementButtonDisabled = isDisabled || isIncrementDisabled;

    return (
        <>
            <div className={classes.wrapper}>
                <Button
                    aria-label={decreaseButtonTitle}
                    aria-disabled={isDecrementButtonDisabled}
                    className={isDecrementButtonDisabled ? classes.buttonDisabled : classes.button}
                    disabled={isDecrementButtonDisabled}
                    onClick={handleDecrement}
                    title={decreaseButtonTitle}
                    type="button"
                    role="button"
                    variant={'secondary'}
                >
                    <MinusIcon />
                </Button>
                <Field
                    aria-disabled={isDisabled}
                    aria-label={tGlobal('quantity')}
                    field={QUANTITY_FIELD}
                    name={QUANTITY_FIELD}
                    id={itemId}
                    inputMode="numeric"
                    min={min}
                    onBlur={handleBlur}
                    onChange={maskInput}
                    pattern="[0-9]*"
                    className={classes.input}
                    disabled={isDisabled}
                    type={'text'}
                />
                <Button
                    aria-label={increaseButtonTitle}
                    aria-disabled={isIncrementButtonDisabled}
                    className={isIncrementButtonDisabled ? classes.buttonDisabled : classes.button}
                    disabled={isIncrementButtonDisabled}
                    onClick={handleIncrement}
                    title={increaseButtonTitle}
                    type="button"
                    role="button"
                    variant={'secondary'}
                >
                    <PlusIcon />
                </Button>
            </div>
            {message ? <FormError errors={message} /> : null}
        </>
    );
};

const Quantity = (props: IQuantityProps): ReactElement => {
    return (
        <Formik
            initialValues={{
                quantity: props.initialValue || 1,
            }}
            onSubmit={() => {}}
        >
            <Form>
                <QuantityFields {...props} />
            </Form>
        </Formik>
    );
};

Quantity.displayName = 'Quantity';

QuantityFields.displayName = 'QuantityFields';

export default Quantity;
