import React, { ReactElement, useMemo } from 'react';
import { useTranslations } from 'next-intl';
import { useReCaptcha } from '@/integrations/google-recaptcha';
import { useFormUpdate } from '@/components/Newsletter/FormUpdate/useFormUpdate';
import { Form, Formik, Field } from 'formik';
import Notification from '@/components/Notification';
import TextInput from '@/components/TextInput';
import Button from '@/components/Button';
import Select from '@/components/Select';
import RadioGroup from '@/components/RadioGroup';
import CheckboxWrapper from '@/components/Checkbox/modules/checkboxWrapper';
import PolicyLink from '@/components/Newsletter/PolicyLink';
import { NOTIFICATION_VARIANTS } from '@/components/Notification/types';

import { validationSchema } from './validationSchema';
import type { INewsLetterFormData, TNewsletterSetState } from '@/components/Newsletter/types';

import classes from '@/components/Newsletter/newsletter.module.scss';
import classesCheckbox from '@/components/Checkbox/checkbox.module.scss';

interface IComponentProps {
    data: INewsLetterFormData;
    email: string;
    setStep: TNewsletterSetState;
}

const FormUpdate = ({ data, email, setStep }: IComponentProps): ReactElement => {
    const tFormError = useTranslations('formError');
    const tNewsletter = useTranslations('newsletter');
    const tForm = useTranslations('form');
    const { ageGroups, countries, countryId, genders, sports } = data;

    const mappedCountries = useMemo(() => {
        // We have 200+ countries
        const mappedCountries: Array<{ label: string; value: string }> = countries.map((country) => ({
            label: country.value,
            value: country.id,
        }));

        return mappedCountries;
    }, [countries]);

    const initValues = {
        ageGroup: '',
        countryId,
        email,
        firstName: '',
        genderId: '',
        lastName: '',
        newsletter: false,
        sports: [],
    };

    const { error, handleSubmit } = useFormUpdate({ setStep });

    const { handleSubmitWithReCaptcha, isReCaptchaLoading, reCaptchaComponent }: any = useReCaptcha({
        onSubmit: handleSubmit,
        reCaptchaConfigField: 'newsletter',
    });

    return (
        <Formik
            initialValues={initValues}
            onSubmit={handleSubmitWithReCaptcha}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
        >
            <Form className={classes.form}>
                <h2 className={classes.title} data-mt-type="newsletter-title">
                    {tNewsletter('title')}
                </h2>
                {error && (
                    <Notification variant={NOTIFICATION_VARIANTS.error}>{tFormError('errorMessage')}</Notification>
                )}
                <div className={classes.message} data-mt-type="newsletter-message">
                    {tNewsletter('signUp')}
                </div>
                <div className={classes.emailWrapper} data-mt-type="newsletter-email">
                    <TextInput
                        aria-label={tNewsletter('emailAddress')}
                        autoComplete="email"
                        field="email"
                        type="email"
                        label={tForm('email')}
                        disabled={true}
                    />
                    {countries && <Select label={tNewsletter('country')} field="countryId" items={mappedCountries} />}
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={isReCaptchaLoading}
                        aria-label={tNewsletter('updateButton')}
                        title={tNewsletter('submitSubscription')}
                    >
                        {tNewsletter('updateButton')}
                    </Button>
                </div>
                <div className={classes.messageBold}>{tNewsletter('subscribeSuccess')}</div>
                <div className={classes.message} data-mt-type="newsletter-message">
                    {tNewsletter('updateInfo')}
                    <br />
                    {tNewsletter('updateTitle')}
                </div>
                <TextInput field="firstName" autoComplete="given-name" label={tNewsletter('firstName')} />
                <TextInput field="lastName" autoComplete="family-name" label={tNewsletter('lastName')} />
                {genders && (
                    <div>
                        <div className={classes.fieldLabel}>{tNewsletter('genderLabel')}</div>
                        <div className={classes.radioGroup}>
                            <RadioGroup
                                field="genderId"
                                items={genders.map(({ id, value }) => {
                                    return { label: value, value: id };
                                })}
                            />
                        </div>
                    </div>
                )}
                {ageGroups && <Select label={tNewsletter('age')} field="ageGroup" items={ageGroups as any} />}
                {sports && (
                    <div className={classes.checkboxGroupWrapper}>
                        <div className={classes.fieldLabel}>{tNewsletter('InterestsLabel')}</div>
                        <div className={classes.checkboxGroup} role="group" aria-labelledby="checkbox-group">
                            {sports.map(({ id, value }) => (
                                <CheckboxWrapper key={`sports_${id}`} id={`sports_${id}`} label={value}>
                                    <Field
                                        className={classesCheckbox.checkboxInput}
                                        type="checkbox"
                                        id={`sports_${id}`}
                                        name="sports"
                                        label={value}
                                        value={id}
                                    />
                                </CheckboxWrapper>
                            ))}
                        </div>
                    </div>
                )}
                <div className={classes.message}>{tNewsletter.rich('updateAgreement', { policyLink: PolicyLink })}</div>
                <Button variant="primary" type="submit" disabled={isReCaptchaLoading}>
                    {tNewsletter('updateButton')}
                </Button>
                {!!reCaptchaComponent && <div className={classes.grecaptchaBadge}>{reCaptchaComponent}</div>}
            </Form>
        </Formik>
    );
};

export default FormUpdate;
