import React, { ReactElement } from 'react';
import { Field } from 'formik';
import mergeClasses from '@/utils/Object/shallowMerge';

import defaultClasses from './radio.module.scss';

interface IRadioOptionProps {
    classes?: {
        radioLabel?: string;
        root?: string;
    };
    dataCustomId?: string;
    disabled?: boolean;
    field: string;
    id?: string;
    label?: ReactElement | string;
    value?: string;
}

const RadioOption = ({ classes: propClasses, field, id, label, value, ...rest }: IRadioOptionProps): ReactElement => {
    const classes = mergeClasses(defaultClasses, propClasses);

    return (
        <div className={classes.root}>
            <div className={classes.radio}>
                <label htmlFor={id} className={classes.radioLabel}>
                    <Field type={'radio'} key={value} id={id} name={field} value={value} field={field} {...rest} />
                    {label || (value != null ? value : '')}
                    <fieldset className={classes.fieldset} aria-hidden="true">
                        <legend className={classes.legend}>
                            <span>{label || field}</span>
                        </legend>
                    </fieldset>
                </label>
            </div>
        </div>
    );
};

RadioOption.displayName = 'RadioOption';

export default RadioOption;
