'use client';

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTopBar } from './hooks/useTopBar';
import RichContent from '@/components/RichContent';
import useCmsLinkClick from '@/hooks/useCmsLinkClick';

import clsx from 'clsx';
import classes from './topbar.module.scss';

export interface TopBarWrapperProps {
    className?: string;
    isTextCentered?: boolean;
}

interface TopBarProps extends TopBarWrapperProps {
    fallbackBlockContent?: string;
    generalBlockContent?: string;
    guestBlockContent?: string;
    proBlockContent?: string;
}

export const TopBar = ({
    className,
    fallbackBlockContent,
    generalBlockContent,
    guestBlockContent,
    isTextCentered,
    proBlockContent,
}: TopBarProps): ReactElement | null => {
    const { content } = useTopBar({ fallbackBlockContent, generalBlockContent, guestBlockContent, proBlockContent });
    const { handleCmsLinkClick } = useCmsLinkClick();

    const headerElementIndex = useRef<number>(0);
    const [shouldAnimateHeader, setShouldAnimateHeader] = useState<boolean>(false);
    const [headerContent, setHeaderContent] = useState<string>(
        typeof content === 'string' ? content : Array.isArray(content) && content?.length ? content[0] : '',
    );

    const rootClassesName = clsx(
        className,
        classes.root,
        !!content && shouldAnimateHeader && classes.animatedPreHeader,
        isTextCentered && classes.isTextCentered,
    );

    // We want to animate the header text if we have more than one string returned
    // Otherwise, we don't animate the header and show the only string returned or nothing
    useEffect(() => {
        if (!content) {
            return;
        }

        if (typeof content === 'string') {
            setShouldAnimateHeader(false);
            setHeaderContent(content);

            return;
        }

        setHeaderContent(content[headerElementIndex.current]);
        setShouldAnimateHeader(true);

        const interval = setInterval(() => {
            headerElementIndex.current = ++headerElementIndex.current % content.length;

            setHeaderContent(content[headerElementIndex.current]);
        }, 9400);

        return () => clearInterval(interval);
    }, [content]);

    return headerContent ? (
        <div onClick={handleCmsLinkClick}>
            <RichContent html={headerContent} classes={{ root: rootClassesName }} />
        </div>
    ) : null;
};

TopBar.displayName = 'TopBar';

export default TopBar;
