import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';

import SuggestedCategory from './suggestedCategory';
import useIsDesktop from '@/hooks/useIsDesktop';

import type { ISuggestedCategoriesProps } from '@/components/SearchBar/types';
import classes from './suggestedCategories.module.scss';

const SuggestedCategories = ({
    fredHopperCategories,
    searchQueryValue,
    setIsSearchOpen,
}: ISuggestedCategoriesProps): ReactElement => {
    const isDesktop = useIsDesktop();
    const t = useTranslations('searchBar');

    const CATEGORIES_QUANTITY_LIMIT = isDesktop ? 4 : 2;
    const items = fredHopperCategories.map((category, index) => {
        const item = (
            <li key={category.catid} className={classes.suggestionItem}>
                <SuggestedCategory
                    searchQueryValue={searchQueryValue}
                    setIsSearchOpen={setIsSearchOpen}
                    fredHopperCategory={category}
                />
            </li>
        );

        return index < CATEGORIES_QUANTITY_LIMIT ? item : null;
    });

    return (
        <>
            <h2 className={classes.suggestionsListHeading}>{t('categoriesHeading')}</h2>
            <ul className={classes.suggestionsList}>{items}</ul>
        </>
    );
};

SuggestedCategories.displayName = 'SuggestedCategories';

export default SuggestedCategories;
