import { useCallback, useEffect, useState } from 'react';

import { useAppContext, useUserContext } from '@/lib/context';
import { useScrollLock } from '@/hooks/useScrollLock';
import { useAwaitQuery } from '@/hooks/useAwaitQuery';
import DEFAULT_OPERATIONS from './api/navigation.gql';
import { STORE_SWITCHER_LOCATION } from '@/constants/storeSwitcher';
import { VIEWS } from '@/constants/navigation';
import type { IUseNavigation, IUseNavigationProps } from './types';

const MENU = 'MENU';
const SIGN_IN = 'SIGN_IN';

const ancestors: { [key: string]: string | null } = {
    CREATE_ACCOUNT: MENU,
    FORGOT_PASSWORD: SIGN_IN,
    MENU: null,
    MY_ACCOUNT: MENU,
    SIGN_IN: MENU,
    STORE_SWITCHER: MENU,
};

export const useNavigation = ({ rootCategoryId }: IUseNavigationProps): IUseNavigation => {
    const { getCustomerQuery } = DEFAULT_OPERATIONS;
    const [
        { drawer, isStoreSwitcherOpen },
        {
            actions: { setIsStoreSwitcherOpen },
            closeDrawer,
        },
    ] = useAppContext();
    const [, { getUserDetails }] = useUserContext();
    const fetchUserDetails = useAwaitQuery(getCustomerQuery);

    useEffect(() => {
        getUserDetails({ fetchUserDetails });
    }, [fetchUserDetails, getUserDetails]);

    const isOpen = drawer === 'nav';

    const [view, setView] = useState(VIEWS.MENU);
    const [categoryTitles, setCategoryTitles] = useState<string[]>([]);
    const [categoryId, setCategoryId] = useState<number>(rootCategoryId);
    const [parentCategoryIdsArray, setParentCategoryIdsArray] = useState<number[]>([]);

    const isTopLevel = categoryId === rootCategoryId;
    const hasModal = view !== VIEWS.MENU;

    useEffect(() => {
        // On a fresh render with cold cache set the current category as root
        // once the root category query completes.
        if ((rootCategoryId && !categoryId) || isTopLevel) {
            setCategoryId(rootCategoryId);
            setCategoryTitles([]);
        }

        if (isTopLevel) {
            setParentCategoryIdsArray([rootCategoryId]);
        }
    }, [categoryId, rootCategoryId, isTopLevel]);

    const changeCurrentCategory = useCallback(
        (name: string, id: number) => {
            setCategoryTitles((prevState) => [...prevState, name]);
            setCategoryId(id);
            setParentCategoryIdsArray((prevState) => [...prevState, id]);
        },
        [setCategoryId, setCategoryTitles],
    );

    const handleBack = useCallback(() => {
        setIsStoreSwitcherOpen({ isOpen: false });
        const parent = ancestors[view];

        if (parent) {
            return setView(parent);
        }

        if (categoryId === parentCategoryIdsArray[1]) {
            return setCategoryId(rootCategoryId);
        }

        setCategoryId(parentCategoryIdsArray[1]);
        setParentCategoryIdsArray((prevState) => prevState.slice(0, -1));
        setCategoryTitles((prevState) => prevState.slice(0, -1));
    }, [categoryId, parentCategoryIdsArray, rootCategoryId, setIsStoreSwitcherOpen, view]);

    const handleClose = useCallback(() => {
        setIsStoreSwitcherOpen({ isOpen: false });
        closeDrawer();
    }, [closeDrawer, setIsStoreSwitcherOpen]);

    const showCreateAccount = useCallback(() => {
        setView(VIEWS.CREATE_ACCOUNT);
    }, [setView]);
    const showForgotPassword = useCallback(() => {
        setView(VIEWS.FORGOT_PASSWORD);
    }, [setView]);
    const showMainMenu = useCallback(() => {
        setView(VIEWS.MENU);
    }, [setView]);
    const showMyAccount = useCallback(() => {
        setView(VIEWS.MY_ACCOUNT);
    }, [setView]);
    const showSignIn = useCallback(() => {
        setView(VIEWS.SIGN_IN);
    }, [setView]);
    const showStoreSwitcher = useCallback(() => {
        setIsStoreSwitcherOpen({ isOpen: true, location: STORE_SWITCHER_LOCATION.TOP_RIGHT });
        setView(VIEWS.STORE_SWITCHER);
    }, [setIsStoreSwitcherOpen]);

    useEffect(() => {
        if (isStoreSwitcherOpen) return;

        setView(VIEWS.MENU);
    }, [isStoreSwitcherOpen]);

    useScrollLock(isOpen);

    return {
        categoryId,
        categoryTitle: categoryTitles.slice().pop() || '',
        changeCurrentCategory,
        handleBack,
        handleClose,
        hasModal,
        isOpen,
        isTopLevel,
        parentCategoryIdsArray,
        showCreateAccount,
        showForgotPassword,
        showMainMenu,
        showMyAccount,
        showSignIn,
        showStoreSwitcher,
        view,
    };
};
