export enum ImageWidthsEnum {
    ICON = 40,
    THUMBNAIL = 80,
    SMALL = 160,
    REGULAR = 360,
    LARGE = 640,
    LARGER = 960,
    XLARGE = 1290,
    XXLARGE = 1600,
    XXXLARGE = 2560,
}
