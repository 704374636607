import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash.throttle';
import { useUpdateMiniCartData } from '@/components/MiniCart';
import { MINI_CART_QUERY } from '@/components/MiniCart/api/miniCart.gql';
import { GENERAL_HEADER_REF, TOP_HEADER_REF } from '@/constants/refIds';
import type { IUseHeader } from '../types';

export const useHeader = (): IUseHeader => {
    useUpdateMiniCartData({ operation: MINI_CART_QUERY });

    const [isHeaderVisible, setIsHeaderVisible] = useState(false);
    const [prevScrollY, setPrevScrollY] = useState(0);

    const controlDirection = useCallback(() => {
        if (window.scrollY === prevScrollY) return;

        if (!window.scrollY) {
            setIsHeaderVisible(false);
        }

        if (window.scrollY <= prevScrollY) {
            setIsHeaderVisible(true);
        } else if (window.scrollY >= prevScrollY) {
            setIsHeaderVisible(false);
        }
        setPrevScrollY(window.scrollY);
    }, [prevScrollY]);

    useEffect(() => {
        const topHeaderHeight = window.document.getElementById(TOP_HEADER_REF)?.offsetHeight;
        const generalHeaderHeight = window.document.getElementById(GENERAL_HEADER_REF)?.offsetHeight;

        if (topHeaderHeight) {
            document.documentElement.style.setProperty('--header-mobile-height', `${topHeaderHeight}px`);
        }

        if (generalHeaderHeight) {
            document.documentElement.style.setProperty('--header-general-height', `${generalHeaderHeight}px`);
        }
    }, []);

    useEffect(() => {
        const throttled = throttle(controlDirection, 500);
        window.addEventListener('scroll', throttled);

        return () => {
            window.removeEventListener('scroll', throttled);
        };
    }, [controlDirection]);

    return {
        isHeaderVisible,
    };
};
