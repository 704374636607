import React, { ReactElement, ReactNode } from 'react';

import defaultClasses from './cardActionOverlay.module.scss';

interface ICardActionOverlayProps {
    children?: ReactNode;
    isLoading?: boolean;
}

const CardActionOverlay = (props: ICardActionOverlayProps): ReactElement => {
    const { children, isLoading } = props;

    return <div className={isLoading ? defaultClasses.loading : defaultClasses.root}>{children}</div>;
};

CardActionOverlay.displayName = 'CardActionOverlay';

export default CardActionOverlay;
