import { useCallback, useMemo, useState, useEffect, type ChangeEvent } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash.debounce';
import type { IUseQuantity, IUseQuantityProps } from './types';

export const QUANTITY_FIELD = 'quantity';

export const useQuantity = ({
    handleDialogOpen,
    initialValue,
    isRemovingCanceled,
    itemId,
    min,
    onChange,
    setIsRemovingCanceled,
}: IUseQuantityProps): IUseQuantity => {
    const [prevQuantity, setPrevQuantity] = useState<number>(initialValue);

    const { setFieldValue, values: formValues } = useFormikContext<{ [QUANTITY_FIELD: string]: number }>();
    const quantity = formValues[QUANTITY_FIELD];

    const isIncrementDisabled = useMemo(() => !quantity, [quantity]);

    // "min: 0" lets a user delete the value and enter a new one, but "1" is
    // actually the minimum value we allow to be set through decrement button.
    const isDecrementDisabled = useMemo(() => !quantity || quantity <= 1, [quantity]);

    // Fire the onChange after some wait time. We calculate the current delay
    // as enough time for a user to spam inc/dec quantity but not enough time
    // for a user to click inc/dec on Product A and then click Product B.
    const debouncedOnChange = useMemo(
        () =>
            debounce((val) => {
                setPrevQuantity(val);
                onChange(val);
            }, 350),
        [onChange],
    );

    // Clear out timeout when the component is unmounted
    useEffect(() => {
        return () => {
            debouncedOnChange.cancel();
        };
    }, [debouncedOnChange]);

    const handleDecrement = useCallback(() => {
        const newQuantity = quantity - 1;
        setFieldValue(QUANTITY_FIELD, newQuantity);
        debouncedOnChange(newQuantity);
    }, [debouncedOnChange, quantity, setFieldValue]);

    const handleIncrement = useCallback(() => {
        const newQuantity = quantity + 1;
        setFieldValue(QUANTITY_FIELD, newQuantity);
        debouncedOnChange(newQuantity);
    }, [debouncedOnChange, quantity, setFieldValue]);

    const handleBlur = useCallback(() => {
        // Only submit the value change if it has changed.
        if (typeof quantity !== 'number') {
            return;
        }

        if (!quantity) {
            handleDialogOpen?.(itemId);
        } else {
            quantity != prevQuantity && debouncedOnChange(quantity);
        }
    }, [quantity, handleDialogOpen, itemId, prevQuantity, debouncedOnChange]);

    const maskInput = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event?.target || {};

            try {
                // For some storefronts decimal values are allowed.
                const nextVal = parseFloat(value);
                if (value && isNaN(nextVal)) throw new Error(`${value} is not a number.`);
                if (isNaN(nextVal) || nextVal < min) return setFieldValue(QUANTITY_FIELD, min);

                return setFieldValue(QUANTITY_FIELD, nextVal);
            } catch (err) {
                console.error(err);

                return setFieldValue(QUANTITY_FIELD, prevQuantity);
            }
        },
        [min, prevQuantity, setFieldValue],
    );

    useEffect(() => {
        setFieldValue(QUANTITY_FIELD, initialValue);
    }, [setPrevQuantity, initialValue, setFieldValue]);

    useEffect(() => {
        if (!isRemovingCanceled) return;
        setFieldValue(QUANTITY_FIELD, prevQuantity);
        setIsRemovingCanceled?.(false);
    }, [isRemovingCanceled, setIsRemovingCanceled, prevQuantity, setFieldValue]);

    return {
        handleBlur,
        handleDecrement,
        handleIncrement,
        isDecrementDisabled,
        isIncrementDisabled,
        maskInput,
    };
};
