import { useCallback, useEffect } from 'react';
import type { IEqualWebConfig } from '@/components/EqualWeb/types';

export const ACCESSIBILITY_ENABLED = 'accessibility-enabled';
interface IUseEqualWeb {
    onClick: () => void;
}

export const useEqualWeb = ({
    equalWebIntegrityKey,
    equalWebWebsiteKey,
    isEqualWebEnabled,
}: IEqualWebConfig): IUseEqualWeb => {
    const storageData = !!globalThis?.localStorage?.getItem(ACCESSIBILITY_ENABLED);

    const initializeEqualWeb = useCallback(() => {
        if (globalThis?.window?.interdeal) return;

        globalThis.window.interdeal = {
            Menulang: 'EN',
            Position: 'Left',
            domains: {
                acc: 'https://access.equalweb.com/',
                js: 'https://cdn.equalweb.com/',
            },
            sitekey: equalWebWebsiteKey,
        };

        const script = Object.assign(document.createElement('script'), {
            crossOrigin: 'anonymous',
            defer: true,
            integrity: equalWebIntegrityKey,
            src: 'https://cdn.equalweb.com/core/3.0.0/accessibility.js',
        });

        globalThis.document.body.appendChild(script);

        return () => {
            globalThis.document.body.removeChild(script);
        };
    }, [equalWebWebsiteKey, equalWebIntegrityKey]);

    useEffect(() => {
        if (!equalWebIntegrityKey || !equalWebWebsiteKey || !isEqualWebEnabled || !storageData) {
            return;
        }

        initializeEqualWeb();
    }, [equalWebIntegrityKey, equalWebWebsiteKey, initializeEqualWeb, isEqualWebEnabled, storageData]);

    const onClick = useCallback(() => {
        initializeEqualWeb();
        globalThis.localStorage.setItem(ACCESSIBILITY_ENABLED, 'true');

        const handleClick = () => globalThis.document.getElementById('INDmenu-btn')?.click();

        if (globalThis?.window?.interdeal) {
            handleClick();

            return;
        }

        globalThis.document.body.addEventListener('INDbuttonRevealed', () => {
            handleClick();
        });
    }, [initializeEqualWeb]);

    return {
        onClick,
    };
};
