import { gql } from '@apollo/client';

export const GET_PRO_ACCOUNT_STATUS = gql`
    query GetProAccountStatus {
        customer {
            pro_account {
                discount_applicable
            }
        }
    }
`;
